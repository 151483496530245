import { RespBody, RespBodyList, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./PageParams";
import { basemodel } from "./basemodel";

export class AdminModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("AdminModel");
    }
    /**
     * id
     */
    id?: number
    /**
     * 用户名
     */
    username?: string
    /**
     * 姓名
     */
    realname?: string
    /**
     * 手机号
     */
    phone?: string
    /**
     * 密码
     */
    password?: string
    /**
     * 头像
     */
    avatar?: string
    /**
     * 性别
     */
    gender?: boolean
    /**
     * 邮箱
     */
    email?: string
    /**
     * 禁用：0=否(默认)，1=是
     */
    isDisable?: boolean
    /**
     * 状态：0=正常(默认)
     */
    status?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
    /**
     * 创建时间：默认为当前时间（自动更新）
     */
    updateTime?: Date
    /**
     * 最后登录时间
     */
    loginTime?: Date
    /**
     * 登录token
     */
    loginToken?: string
}
export interface AdminFilter {
    id?: number | null
    /**
     * 用户名
     */
    username?: string | null
    /**
     * 姓名
     */
    realname?: string | null
    /**
     * phone
     */
    phone?: string | null
    /**
     * 性别
     */
    gender?: boolean | null
}
export interface AdminSearch {
    /**
     * id
     */
    id?: number;
    /**
     * 搜索数量
     */
     key?: string
    /**
     * 搜索数量
     */
    size?: number
}
// API URL： /api/v1/management/admin
// 管理端-管理员相关 | 管理端-管理员接口
export interface IAdmin {
    // 获取 管理员搜索列表
    // GET("/key-search")
    getListSearch(search: AdminSearch): Promise<RespBodyList<AdminModel>>
    // 获取 管理员列表")
    // GET("/list")

    getList(pageParams: PageParams, filter: AdminFilter): Promise<RespBodyPager<AdminModel>>

    // 获取 管理员详情
    // GET("/{id}")
    get(id: number): Promise<RespBody<AdminModel>>

    // 添加 管理员
    // POST
    add(model: AdminModel): Promise<RespBody<boolean>>
    // 修改 管理员
    // PUT
    set(model: AdminModel): Promise<RespBody<boolean>>

    // 删除 管理员, 逗号分割id数组")
    // DELETE("/{ids}")
    remove(ids: Array<number>): Promise<RespBody<number>>
}
