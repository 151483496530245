
import {
  onMounted,
  defineComponent,
  inject,
  reactive,
  ref,
  watch,
  onUpdated
} from "vue";
import { RespBodyList } from "@/apis/interface/Resp";
import { IUser, UserSearch, UserModel } from "@/apis/interface/IUser";

export default defineComponent({
  props: {
    userIdSelected: {
      type: Number,
      default: () => undefined
    }
  },
  emits: ["update:user-id-selected"],
  setup(props, { emit }) {
    const apiUser = inject("apiUser") as IUser;
    let currentUserId = ref<number | undefined>(props.userIdSelected || undefined);

    onUpdated(() => {
      currentUserId.value = props.userIdSelected || undefined;
      search.id = props.userIdSelected || undefined;
      // loadData(search);
    });

    let search = reactive<UserSearch>({
      id: props.userIdSelected,
      size: 20
    });

    let dataList = ref<UserModel[]>([]);

    let loadData = async (search: UserSearch) => {
      loading.value = true;
      const resp = await apiUser.getListSearch(search);
      const pager = resp as RespBodyList<UserModel>;
      let list: Array<UserModel> = pager.object;
      dataList.value = [
        new UserModel({
          id: undefined,
          username: "- 不选择 -"
        }),
        ...list
      ];
      loading.value = false;
    };

    watch(currentUserId, (n: number | undefined) => {
      emit("update:user-id-selected", n);
    });

    let remoteSearchMethod = (key: string) => {
      search.id = undefined;
      search.key = key;
      loadData(search);
    };

    onMounted(() => {
      loadData(search);
    });

    const loading = ref(false);
    return {
      currentUserId,
      dataList,
      loading,
      remoteSearchMethod
    };
  }
});
