import AdminView from "@/views/AdminManager/View.vue";
import AdminLogView from "@/views/AdminLogManager/View.vue";
import AdminPowerView from "@/views/AdminPowerManager/View.vue";
import InformationView from "@/views/InformationManager/View.vue";
import AppletSettingView from "@/views/AppletSettingManager/View.vue";
import CompanyView from "@/views/CompanyManager/View.vue";
import CompanyServBindView from "@/views/CompanyServBindManager/View.vue";
import FormNeedWhatView from "@/views/FormNeedWhatManager/View.vue";
import FormServiceGeneralView from "@/views/FormServiceGeneralManager/View.vue";
import FormServiceJobApplyView from "@/views/FormServiceJobApplyManager/View.vue";
import FormServiceJobInviteView from "@/views/FormServiceJobInviteManager/View.vue";
import ServiceActivityView from "@/views/ServiceActivityManager/View.vue";
import ServiceBeanView from "@/views/ServiceBeanManager/View.vue";
import ServiceGeneralView from "@/views/ServiceGeneralManager/View.vue";
import ServicePageView from "@/views/ServicePageManager/View.vue";
import SysLogView from "@/views/SysLogManager/View.vue";
import UserFavoriteView from "@/views/UserFavoriteManager/View.vue";
import UserLogView from "@/views/UserLogManager/View.vue";
import UserView from "@/views/UserManager/View.vue";
import UserPowerView from "@/views/UserPowerManager/View.vue";
import SysResourceView from "@/views/UserResourceManager/View.vue";

export {
    AdminView, AdminLogView, AdminPowerView, SysResourceView, InformationView, AppletSettingView, CompanyView, CompanyServBindView, FormNeedWhatView, FormServiceGeneralView, FormServiceJobApplyView, FormServiceJobInviteView, ServiceActivityView, ServiceBeanView, ServiceGeneralView, ServicePageView, SysLogView, UserFavoriteView, UserLogView, UserView, UserPowerView
};

export default {
    install: (app: import("vue").App<any>): void => {
        app.component("AdminView", AdminView);
        app.component("AdminLogView", AdminLogView);
        app.component("AdminPowerView", AdminPowerView);
        app.component("InformationView", InformationView);
        app.component("AppletSettingView", AppletSettingView);
        app.component("CompanyView", CompanyView);
        app.component("CompanyServBindView", CompanyServBindView);
        app.component("FormNeedWhatView", FormNeedWhatView);
        app.component("FormServiceGeneralView", FormServiceGeneralView);
        app.component("FormServiceJobApplyView", FormServiceJobApplyView);
        app.component("FormServiceJobInviteView", FormServiceJobInviteView);
        app.component("ServiceActivityView", ServiceActivityView);
        app.component("ServiceBeanView", ServiceBeanView);
        app.component("ServiceGeneralView", ServiceGeneralView);
        app.component("ServicePageView", ServicePageView);
        app.component("SysLogView", SysLogView);
        app.component("UserFavoriteView", UserFavoriteView);
        app.component("UserLogView", UserLogView);
        app.component("UserView", UserView);
        app.component("UserPowerView", UserPowerView);
        app.component("SysResourceView", SysResourceView);
    }
};