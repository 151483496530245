import {
    ISystem, /*SysResourceFilter, */ SysCityFilter, SysCityModel, SysCountyFilter, SysCountyModel, SysProvModel, SysTownFilter, SysTownModel, SysVillageFilter, /*,SysProvModel, SysCityModel, SysCountyModel, SysTownModel, SysVillageModel*/
    SysVillageModel
} from "./interface/ISystem";
import { RespBody } from "@/apis/interface/Resp";
import request from "@/utils/request";
// API URL： /api/v1/management/system
// 管理端-系统接口
export class ApiSystem implements ISystem {

    private path = "/api/v1/management/system";

    // 获取 省-市 所有
    // GET("/prov-city/all")
    public async provAndCityList(): Promise<RespBody<{ provList: Array<SysProvModel>, cityList: Array<SysCityModel> }>> {
        const url = `${this.path}/prov-city/all`;
        const result = await request.get(url);
        console.table(["API@GET@获取 省-市 所有", this.path, url, result]);
        return result.data;
    }
    // 获取 省-市-县 所有
    // GET("/prov-city-county/all")
    public async provAndCityAndCountyList(): Promise<RespBody<{ provList: Array<SysProvModel>, cityList: Array<SysCityModel>, countyList: Array<SysCountyModel> }>> {
        const url = `${this.path}/prov-city-county/all`;
        const result = await request.get(url);
        console.table(["API@GET@获取 省-市-县 所有", this.path, url, result]);
        return result.data;
    }
    // 获取 省-市-县-乡 所有
    // GET("/prov-city-county-town/all")
    public async provAndCityAndCountyAndTownList(): Promise<RespBody<{ provList: Array<SysProvModel>, cityList: Array<SysCityModel>, countyList: Array<SysCountyModel>, townList: Array<SysTownModel> }>> {
        const url = `${this.path}/prov-city-county-town/all`;
        const result = await request.get(url);
        console.table(["API@GET@获取 省-市-县-乡 所有", this.path, url, result]);
        return result.data;
    }
    // 获取 省 列表
    // GET("/prov-list")
    public async provList(): Promise<RespBody<Array<SysProvModel>>> {
        const url = `${this.path}/prov-list`;
        const result = await request.get(url);
        console.table(["API@GET@获取 省 列表", this.path, url, result]);
        return result.data;
    }
    // 根据 省 获取  市 列表
    // GET("/prov/city-list")
    public async cityList(filter: SysCityFilter): Promise<RespBody<Array<SysCityModel>>> {
        const url = `${this.path}/prov/city-list`;
        const result = await request.get(url);
        console.table(["API@GET@根据 省 获取  市 列表", this.path, url, filter, result]);
        return result.data;
    }
    // 根据 市 获取 县 列表
    // GET("/prov/city/county-list")
    public async countyList(filter: SysCountyFilter): Promise<RespBody<Array<SysCountyModel>>> {
        const url = `${this.path}/prov/city/county-list`;
        const result = await request.get(url);
        console.table(["API@GET@根据 市 获取 县 列表", this.path, url, filter, result]);
        return result.data;
    }
    // 根据 县 获取 街道 列表
    // GET("/prov/city/county/town-list")
    public async townList(filter: SysTownFilter): Promise<RespBody<Array<SysTownModel>>> {
        const url = `${this.path}/prov/city/county/town-list`;
        const result = await request.get(url);
        console.table(["API@GET@根据 县 获取 街道 列表", this.path, url, filter, result]);
        return result.data;
    }
    // 根据 街道 获取 委会 列表
    // GET("/prov/city/county/town/village-list")
    public async villageList(filter: SysVillageFilter): Promise<RespBody<Array<SysVillageModel>>> {
        const url = `${this.path}/prov/city/county/town/village-list`;
        const result = await request.get(url);
        console.table(["API@GET@根据 街道 获取 委会 列表", this.path, url, filter, result]);
        return result.data;
    }
}