import * as Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import "default-passive-events";

const app = Vue.createApp(App);
declare let window: any;
import { RespBody } from "@/apis/interface/Resp";

// 引入样式
import "normalize.css";
import "@/styles/base.scss";
import "@/styles/reset-element.scss";

// element-plus
import UseElement from "@/plugins/element-plus";
app.use(UseElement);

import dialogSubs from "@/layout/includes/dialog-install";
app.use(dialogSubs);
import drawerSubs from "@/layout/includes/drawer-install";
app.use(drawerSubs);
import api from "@/apis/index";
app.use(api);
import { apiAuth, apiSystem, apiEnum, apiAdminHome } from "./apis/index";

// svg
import "@/icons/index";
import SvgIcon from "./icons/SvgIcon.vue";
app.component("svg-icon", SvgIcon);

// 全局组件
import RegisterComponent from "./components";
app.use(RegisterComponent);
const formRes = require("@/utils/form-res.json");

import { getProv, getCity, getCounty, getTown } from "@/utils/area-res";
import { getIndustry } from "@/utils/enum-res";
app.mixin({
    methods: {
        getActivityColor(data: any) {
            if (moment(new Date()).isBefore(moment(data.actTime))) {
                return "#409EFF";
            }
            if (
                moment(new Date()).isAfter(moment(data.actTime)) &&
                moment(new Date()).isBefore(moment(data.actTimeEnd))
            ) {
                return "#67C23A";
            }
            if (moment(new Date()).isAfter(moment(data.actTimeEnd))) {
                return "#F56C6C";
            }
        },
        getActivityTitle(data: any) {
            if (moment(new Date()).isBefore(moment(data.actTime))) {
                return "未开始";
            }
            if (
                moment(new Date()).isAfter(moment(data.actTime)) &&
                moment(new Date()).isBefore(moment(data.actTimeEnd))
            ) {
                return "进行中";
            }
            if (moment(new Date()).isAfter(moment(data.actTimeEnd))) {
                return "已结束";
            }
        },
        terminalFormat(terminal: string) {
            return terminal == "applet" ? "微信小程序" : "管理后台";
        },
        statusFormat(status: number) {
            return status == 0 ? "正常" : "未定义";
        },
        dateTimeFormat(t: any) {
            return moment(t).format("YYYY-MM-DD HH:mm:ss");
        },
        dateTimeFormatM(t: any) {
            return moment(t).format("YY/MM/DD HH:mm");
        },
        dateFormat(t: any) {
            return moment(t).format("YYYY-MM-DD");
        },
        dateTimeFormatMC(t: any) {
            return moment(t).format("YYYY年MM月DD日");
        },
        disableFormat(disable: boolean) {
            return disable ? "禁用" : "可用";
        },
        getProvName: (code: string): string | undefined => {
            return getProv(code)?.prov || "-";
        },
        getCityName: (code: string): string | undefined => {
            return getCity(code)?.city || "-";
        },
        getCountyName: (code: string): string | undefined => {
            return getCounty(code)?.county || "-";
        },
        getTownName: (code: string): string | undefined => {
            return getTown(code)?.town || "-";
        },
        getFormName(key: string) {
            if (key)
                return key.split(",").map(k => `[${formRes[k] || k}]`).join("，");
            return "无";
        },
        getFavTypeName(name: string): string {
            return name;
        },
        getIndustryName: (code: string): string | undefined => {
            return getIndustry(code)?.enumName || code || "-";
        }
    }
});

import { LocalStorage } from "storage-manager-js";
import { ElLoading } from "element-plus";
import authHandler from "./utils/auth-handler";
(() => {

    const getSchemas = async () => {
        if (!LocalStorage.get("schemas")) {
            const data = await apiAuth.getSchemas()
            LocalStorage.set("schemas", data);
        }
    }

    const provAndCityAndCountyAndTownList = async () => {
        if (!LocalStorage.get("prov_city_county_town_list")) {
            const data = (await apiSystem.provAndCityAndCountyAndTownList() as RespBody<any>).object;
            LocalStorage.set("prov_city_county_town_list", data);
        }
    }

    const industryEnumList = async () => {
        if (!LocalStorage.get("industry_enum_list")) {
            const data = (await apiEnum.industryEnumList() as RespBody<any>).object;
            LocalStorage.set("industry_enum_list", data);
        }
    }

    //初始化配置数据
    const loadingInstance = ElLoading.service({ fullscreen: true });
    Promise.all([getSchemas(), provAndCityAndCountyAndTownList(), industryEnumList()]).then(result => {
        app.use(store).use(router).mount("#app");
        loadingInstance.close();

        if (!authHandler.isLogin()) { //检查登录状态
            router.push("/login");
        } else {
            //设置路由菜单
            store.dispatch("authData/INITIALIZE").then(() => {
                router.push(window.location.href.split("#")[1] || "/home");
            });
        }
    });
})();

import bus from "@/utils/bus";

const loadCountData = async () => {
    const { object } = await apiAdminHome.getCount();
    LocalStorage.set("COUNT-DATA", object);
    bus.emit("on-count-data-change");
}

setInterval(() => {
    loadCountData();
}, 10 * 1000);
loadCountData();