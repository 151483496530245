import { IServiceBean, ServiceBeanModel/*, ServiceBeanFilter */ } from "./interface/IServiceBean";
import { RespBody, RespBodyList } from "@/apis/interface/Resp";
import request from "@/utils/request";
import { ServiceGroupModel, ServiceGroupMenuModel } from "./interface/IServiceGroup";
// API URL： /api/v1/management/service-bean
// 管理端-服务相关 | 管理端-服务导航
export class ApiServiceBean implements IServiceBean {

    private path = "/api/v1/management/service-bean";

    // 获取公共服务 组-菜单
    // GET("/menu")
    public async menus(): Promise<RespBody<Array<ServiceGroupMenuModel>>> {
        const url = `${this.path}/menu`;
        const result = await request.get(url);
        console.table(["API@GET@获取公共服务 组-菜单", this.path, url, result]);

        const ret = result.data as RespBodyList<ServiceGroupModel>;
        ret.object = ret.object.map((item: any) => {
            const model: ServiceGroupMenuModel = new ServiceGroupMenuModel(item);
            model.services = item.services.map((s: any) => new ServiceBeanModel(s));
            return model;
        });

        return result.data;
    }
    // 获取 组列表
    // GET("/all")
    public async getAll(): Promise<RespBodyList<ServiceBeanModel>> {
        const url = `${this.path}/all`;
        const result = await request.get(url);
        console.table(["API@GET@获取 组列表", this.path, url, result]);
        const ret = result.data as RespBodyList<ServiceBeanModel>;
        ret.object = ret.object.map((item: any) => new ServiceBeanModel(item));
        return ret;
    }
    // 添加 组
    // POST
    public async add(model: ServiceBeanModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.post(url, { ...model });
        console.table(["API@POST@添加 组", this.path, url, model, result]);
        return result.data;
    }
    // 修改 组
    // PUT
    public async set(model: ServiceBeanModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.put(url, { ...model });
        console.table(["API@PUT@修改 组", this.path, url, model, result]);
        return result.data;
    }
    // 删除 模块, 逗号分割id数组
    // DELETE("/{ids}")
    public async remove(ids: Array<number>): Promise<RespBody<number>> {
        const url = `${this.path}/${ids.join(",")}`;
        const result = await request.delete(url);
        console.table(["API@DELETE@删除 模块, 逗号分割id数组", this.path, url, ids, result]);
        return result.data;
    }
}