import { IAuth } from "./interface/IAuth";
import { IAdmin } from "./interface/IAdmin";
import { IAdminLog } from "./interface/IAdminLog";
import { IApplicationConfig } from "./interface/IApplicationConfig";
import { ICompany } from "./interface/ICompany";
import { IEnum } from "./interface/IEnum";
import { IFormNeedWhat } from "./interface/IFormNeedWhat";
import { IFormServiceGeneral } from "./interface/IFormServiceGeneral";
import { IFormServiceJobApply } from "./interface/IFormServiceJobApply";
import { IFormServiceJobInvite } from "./interface/IFormServiceJobInvite";
import { IInformation } from "./interface/IInformation";
import { IServiceActivity } from "./interface/IServiceActivity";
import { IServiceBean } from "./interface/IServiceBean";
import { IServiceGeneral } from "./interface/IServiceGeneral";
import { IServiceGroup } from "./interface/IServiceGroup";
import { IServicePage } from "./interface/IServicePage";
import { ISystem } from "./interface/ISystem";
import { IUser } from "./interface/IUser";
import { IUserFavorite } from "./interface/IUserFavorite";
import { IUserLog } from "./interface/IUserLog";
import { IUserRole } from "./interface/IUserRole";
import { IAdminRole } from "./interface/IAdminRole";
import { ISysResource } from "./interface/ISysResource";
import { ISysRole } from "./interface/ISysRole";
import { IOther } from "./interface/IOther";

import { ApiAuth } from "./ApiAuth";
import { ApiAdmin } from "./ApiAdmin";
import { ApiAdminLog } from "./ApiAdminLog";
import { ApiApplicationConfig } from "./ApiApplicationConfig";
import { ApiCompany } from "./ApiCompany";
import { ApiEnum } from "./ApiEnum";
import { ApiFormNeedWhat } from "./ApiFormNeedWhat";
import { ApiFormServiceGeneral } from "./ApiFormServiceGeneral";
import { ApiFormServiceJobApply } from "./ApiFormServiceJobApply";
import { ApiFormServiceJobInvite } from "./ApiFormServiceJobInvite";
import { ApiInformation } from "./ApiInformation";
import { ApiServiceActivity } from "./ApiServiceActivity";
import { ApiServiceBean } from "./ApiServiceBean";
import { ApiServiceGeneral } from "./ApiServiceGeneral";
import { ApiServiceGroup } from "./ApiServiceGroup";
import { ApiServicePage } from "./ApiServicePage";
import { ApiSystem } from "./ApiSystem";
import { ApiUser } from "./ApiUser";
import { ApiUserFavorite } from "./ApiUserFavorite";
import { ApiUserLog } from "./ApiUserLog";
import { ApiUserRole } from "./ApiUserRole";
import { ApiAdminRole } from "./ApiAdminRole";
import { ApiSysResource } from "./ApiSysResource";
import { ApiSysRole } from "./ApiSysRole";
import { IAdminHome } from "@/apis/interface/IAdminHome";
import { ApiAdminHome } from "@/apis/ApiAdminHome";
import { ApiOther } from "./ApiOther";

export const apiAuth: IAuth = new ApiAuth();
export const apiAdmin: IAdmin = new ApiAdmin();
export const apiAdminLog: IAdminLog = new ApiAdminLog();
export const apiApplicationConfig: IApplicationConfig =
  new ApiApplicationConfig();
export const apiCompany: ICompany = new ApiCompany();
export const apiEnum: IEnum = new ApiEnum();
export const apiFormNeedWhat: IFormNeedWhat = new ApiFormNeedWhat();
export const apiFormServiceGeneral: IFormServiceGeneral =
  new ApiFormServiceGeneral();
export const apiFormServiceJobApply: IFormServiceJobApply =
  new ApiFormServiceJobApply();
export const apiFormServiceJobInvite: IFormServiceJobInvite =
  new ApiFormServiceJobInvite();
export const apiInformation: IInformation = new ApiInformation();
export const apiServiceActivity: IServiceActivity = new ApiServiceActivity();
export const apiServiceBean: IServiceBean = new ApiServiceBean();
export const apiServiceGeneral: IServiceGeneral = new ApiServiceGeneral();
export const apiServiceGroup: IServiceGroup = new ApiServiceGroup();
export const apiServicePage: IServicePage = new ApiServicePage();
export const apiSystem: ISystem = new ApiSystem();
export const apiUser: IUser = new ApiUser();
export const apiUserFavorite: IUserFavorite = new ApiUserFavorite();
export const apiUserLog: IUserLog = new ApiUserLog();

export const apiUserRole: IUserRole = new ApiUserRole();
export const apiAdminRole: IAdminRole = new ApiAdminRole();
export const apiSysResource: ISysResource = new ApiSysResource();
export const apiSysRole: ISysRole = new ApiSysRole();
export const apiAdminHome: IAdminHome = new ApiAdminHome();
export const apiOther: IOther = new ApiOther();

export default {
  install: (app: import("vue").App<any>): void => {
    app.provide("apiAuth", apiAuth);
    app.provide("apiAdmin", apiAdmin);
    app.provide("apiAdminLog", apiAdminLog);
    app.provide("apiApplicationConfig", apiApplicationConfig);
    app.provide("apiCompany", apiCompany);
    app.provide("apiEnum", apiEnum);
    app.provide("apiFormNeedWhat", apiFormNeedWhat);
    app.provide("apiFormServiceGeneral", apiFormServiceGeneral);
    app.provide("apiFormServiceJobApply", apiFormServiceJobApply);
    app.provide("apiFormServiceJobInvite", apiFormServiceJobInvite);
    app.provide("apiInformation", apiInformation);
    app.provide("apiServiceActivity", apiServiceActivity);
    app.provide("apiServiceBean", apiServiceBean);
    app.provide("apiServiceGeneral", apiServiceGeneral);
    app.provide("apiServiceGroup", apiServiceGroup);
    app.provide("apiServicePage", apiServicePage);
    app.provide("apiSystem", apiSystem);
    app.provide("apiUser", apiUser);
    app.provide("apiUserFavorite", apiUserFavorite);
    app.provide("apiUserLog", apiUserLog);
    app.provide("apiUserRole", apiUserRole);
    app.provide("apiAdminRole", apiAdminRole);
    app.provide("apiSysResource", apiSysResource);
    app.provide("apiSysRole", apiSysRole);
    app.provide("apiAdminHome", apiAdminHome);
    app.provide("apiOther", apiOther);
  }
};
