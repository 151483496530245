import { RespBody } from "@/apis/interface/Resp";
import { IApplicationConfig, ApplicationConfigModel } from "./interface/IApplicationConfig";
import request from "@/utils/request";
// API URL： /api/v1/management/application-config
// 管理端-程序配置
export class ApiApplicationConfig implements IApplicationConfig {

	private path = "/api/v1/management/application-config";

	// 获取 配置
	// GET("/{key}")
	public async appletConfigByKey(key: string): Promise<RespBody<any>> {
		const url = `${this.path}/key/${encodeURI(key)}`;
		const result = await request.get(url);
		console.table(["API@GET@获取 配置", this.path, url, key, result]);
		return result.data;
	}
	// 获取 配置
	// GET("/{id}")
	public async appletConfigById(id: number): Promise<RespBody<any>> {
		const url = `${this.path}/id/${id}`;
		const result = await request.get(url);
		console.table(["API@GET@获取 配置", this.path, url, id, result]);
		return result.data;
	}
	// 保存 配置
	// POST
	public async updateOrSave(model: ApplicationConfigModel): Promise<RespBody<boolean>> {
        console.table(model);
		const url = `${this.path}`;
		const result = await request.post(url,  { ...model } );
		console.table(["API@POST@保存 配置", this.path, url, model, result]);
		return result.data;
	}
	// 删除 配置, 逗号分割id数组
	// DELETE("/{ids}")
	public async remove(ids: Array<number>): Promise<RespBody<number>> {
		const url = `${this.path}/ids/${ids.join(",")}`;
		const result = await request.delete(url);
		console.table(["API@DELETE@删除 配置, 逗号分割id数组", this.path, url, ids, result]);
		return result.data;
	}
}