
import {
  onMounted,
  defineComponent,
  inject,
  reactive,
  ref,
  watch,
  onUpdated
} from "vue";
import { RespBodyList } from "@/apis/interface/Resp";
import { IAdmin, AdminSearch, AdminModel } from "@/apis/interface/IAdmin";

export default defineComponent({
  props: {
    adminIdSelected: {
      type: Number,
      default: () => 0
    }
  },
  emits: ["update:admin-id-selected"],
  setup(props, { emit }) {
    const apiAdmin = inject("apiAdmin") as IAdmin;
    let currentAdminId = ref<number | undefined>(props.adminIdSelected || undefined);

    onUpdated(() => {
      currentAdminId.value = props.adminIdSelected || undefined;
      search.id = props.adminIdSelected || undefined;
      // loadData(search);
    });

    let search = reactive<AdminSearch>({
      id: props.adminIdSelected,
      key: "",
      size: 20
    });

    let dataList = ref<AdminModel[]>([]);

    let loadData = async (search: AdminSearch) => {
      loading.value = true;
      const resp = await apiAdmin.getListSearch(search);
      const pager: RespBodyList<AdminModel> = resp;
      let list: Array<AdminModel> = pager.object;
      dataList.value = [
        new AdminModel({
          id: undefined,
          username: "- 不选择 -"
        }),
        ...list
      ];
      loading.value = false;
    };

    watch(currentAdminId, (n: number | undefined) => {
      emit("update:admin-id-selected", n);
    });

    let remoteSearchMethod = (key: string) => {
      search.id = undefined;
      search.key = key;
      loadData(search);
    };

    onMounted(() => {
      loadData(search);
    });

    const loading = ref(false);
    return {
      currentAdminId,
      dataList,
      loading,
      remoteSearchMethod
    };
  }
});
