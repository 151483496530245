import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isDisable('view'))
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          title: "查看详情",
          onClick: _withModifiers(_ctx.showObject, ["prevent"]),
          type: "success",
          plain: "",
          size: "mini",
          icon: _ctx.icon.view,
          circle: ""
        }, null, 8, ["onClick", "icon"]))
      : _createCommentVNode("", true),
    (!_ctx.isDisable('edit'))
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          title: "编辑",
          onClick: _withModifiers(_ctx.editObject, ["prevent"]),
          type: "primary",
          plain: "",
          size: "mini",
          icon: _ctx.icon.edit,
          circle: ""
        }, null, 8, ["onClick", "icon"]))
      : _createCommentVNode("", true),
    (!_ctx.isDisable('delete'))
      ? (_openBlock(), _createBlock(_component_el_popconfirm, {
          key: 2,
          "confirm-button-text": "是",
          "cancel-button-text": "不，谢谢",
          icon: _ctx.icon.infoFilled,
          "icon-color": "red",
          title: "您确认删除？删除后无法恢复，请谨慎操作。",
          onConfirm: _ctx.deleteObject
        }, {
          reference: _withCtx(() => [
            _createVNode(_component_el_button, {
              title: "删除",
              type: "danger",
              plain: "",
              size: "mini",
              icon: _ctx.icon.delete,
              circle: ""
            }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["icon", "onConfirm"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 64))
}