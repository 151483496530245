
import { defineComponent, ref, Ref } from "vue";
import { ServiceActivityModel } from "@/apis/interface/IServiceActivity";

export default defineComponent({
  setup() {
    let expanded = ref(false);
    let dataList: Ref<ServiceActivityModel[] | null> = ref([]);
    let dataRow: Ref<ServiceActivityModel | null> = ref(null);
    let companyId = ref(null);

    let setData = async (list: Ref, row: Ref) => {
      console.log("--->view ServiceActivityModel", dataList, dataRow);
      dataList.value = list.value;
      dataRow.value = row.value;
      // //重新获取最新详细数据
      // const ret = (await apiServiceActivity.get(
      //   companyId.value || 0,
      //   dataRow.value?.id || 0
      // )) as RespBody<ServiceActivityModel>;
      // dataRow.value = ret.object;
    };
    return {
      drawerSize: "800px",
      expanded,
      setData,
      companyId,
      formModel: dataRow,
    };
  },
});
