import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.formModel)
      ? (_openBlock(), _createBlock(_component_el_form, {
          key: 0,
          ref: "formRef",
          size: "mini",
          "label-position": "left",
          "label-width": "100px",
          model: _ctx.formModel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "组名",
              prop: "groupName",
              rules: [
          { required: true, message: '请填写组名', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '长度在 2 到 10 个字符',
            trigger: 'change',
          },
        ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  style: {"width":"150px"},
                  placeholder: "输入组名",
                  modelValue: _ctx.formModel.groupName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.groupName) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "分组图标",
              prop: "groupIcon",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  placeholder: "输入分组图标",
                  modelValue: _ctx.formModel.groupIcon,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formModel.groupIcon) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "排序",
              prop: "indexSort"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  style: {"width":"80px"},
                  placeholder: "输入排序",
                  modelValue: _ctx.formModel.indexSort,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.indexSort) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"]))
      : _createCommentVNode("", true)
  ]))
}