
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
declare let window: any;

export default defineComponent({
  props: {
    textValue: {
      type: String,
      default: () => ""
    },
    initialHeight: {
      type: Number,
      default: () => 100
    }
  },
  emits: ["update:text-value"],
  setup(props, { emit }) {
    let frame = ref();

    onMounted(() => {
      frame.value.onload = () => {
        frame.value.contentWindow.sendMessageToFrame(props.textValue);
      };

      window.receiveFrameMessage = (content: string) => {
        // console.log("接收到" + content);
        emit("update:text-value", content);
      };
    });

    onUnmounted(() => {
      window.receiveFrameMessage = null;
    });

    // let hasContent = () => {};

    return { frame };
  }
});
