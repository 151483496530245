import { Resp } from "@/apis/interface/Resp";
import { basemodel } from "./basemodel";
import { PageParams } from "./PageParams";
export class UserFavoriteModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("UserFavoriteModel");
    }
    id?: number
    /**
     * 收藏来自表：service_activity, service_general, service_page, information
     */
    favTable?: string
    /**
     * 收藏的表id
     */
    favId?: number
    //@=>title
    title?: number
    /**
     * 用户id
     */
    userId?: number
    //@=>userId
    username?: string
    //@=>userId
    realname?: string
    /**
     * 禁用：0=否(默认)，1=是
     */
    isDisable?: boolean
    /**
     * 状态：0=正常(默认)
     */
    status?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
    /**
     * 创建时间：默认为当前时间（自动更新）
     */
    updateTime?: Date
}
export interface UserFavoriteFilter {
    /**
     * 收藏来自表
     */
    favTable?: string | null
    /**
     * 用户id
     */
    userId?: number | null
}
// API URL： /api/v1/management/user-favorite
// 管理端-用户相关 | 管理端-用户关注
export interface IUserFavorite {
    // 获取用户关注的列表
    // GET("/list-full")
    getList(pageParams: PageParams, filter: UserFavoriteFilter): Promise<Resp>
}
