import { IFormServiceJobApply, FormServiceJobApplyModel, FormServiceJobApplyFilter } from "./interface/IFormServiceJobApply";
import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./interface/PageParams";
import request from "@/utils/request";
// API URL： /api/v1/management/form-service-job-apply
// 管理端-表单相关 | 管理端-我的应聘表单
export class ApiFormServiceJobApply implements IFormServiceJobApply {

    private path = "/api/v1/management/form-service-job-apply";

    // 获取 我的应聘表单列表
    // GET("/list")
    public async getList(pageParams: PageParams, filter: FormServiceJobApplyFilter): Promise<RespBodyPager<FormServiceJobApplyModel>> {
        const url = `${this.path}/list`;
        const result = await request.get(url, { params: { ...pageParams, ...filter } });
        console.table(["API@GET@获取 我的应聘表单列表", this.path, url, pageParams, filter, result]);
        const ret = result.data as RespBodyPager<FormServiceJobApplyModel>;
        ret.object.listData = ret.object.listData?.map((item: any) => new FormServiceJobApplyModel(item));
        return ret;
    }
    // 获取 我的应聘表单详情
    // GET("/{id}")
    public async get(id: number): Promise<RespBody<FormServiceJobApplyModel>> {
        const url = `${this.path}/${id}`;
        const result = await request.get(url);
        console.table(["API@GET@获取 我的应聘表单详情", this.path, url, id, result]);
        const ret = result.data as RespBody<FormServiceJobApplyModel>;
        ret.object = new FormServiceJobApplyModel(ret.object);
        return result.data;
    }
    // 添加 我的应聘表单
    // POST
    public async add(model: FormServiceJobApplyModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.post(url,  { ...model } );
        console.table(["API@POST@添加 我的应聘表单", this.path, url, model, result]);
        return result.data;
    }
    // 修改 我的应聘表单
    // PUT
    public async set(model: FormServiceJobApplyModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.put(url,  { ...model } );
        console.table(["API@PUT@修改 我的应聘表单", this.path, url, model, result]);
        return result.data;
    }
    // 删除 我的应聘表单, 逗号分割id数组
    // DELETE("/{ids}")
    public async remove(ids: Array<number>): Promise<RespBody<number>> {
        const url = `${this.path}/${ids.join(",")}`;
        const result = await request.delete(url);
        console.table(["API@DELETE@删除 我的应聘表单, 逗号分割id数组", this.path, url, ids, result]);
        return result.data;
    }
}