import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./PageParams";
import { basemodel } from "./basemodel";
export class FormServiceGeneralModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("FormServiceGeneralModel");
    }
    id?: number
    /**
     * 发送人
     */
    senderUserId?: number
    //@=>senderUserId
    senderUsername?: string
    //@=>senderUserId
    senderRealname?: string
    /**
     * 服务类型id
     */
    serviceBeanId?: number
    /**
     * 服务类型标题
     */
    serviceBeanTitle?: string
    /**
     * 服务类型
     */
    serviceKey?: string
    /**
     * 服务id
     */
    serviceId?: number
    //@=>serviceId
    serviceTitle?: string
    /**
     * 用户名
     */
    personName?: string
    /**
     * 电话
     */
    linkPhone?: string
    /**
     * companyName
     */
    companyName?: string
    /**
     * 邮箱
     */
    email?: string
    /**
     * 职位
     */
    position?: string
    /**
     * 需求描述
     */
    requirement?: string
    /**
     * 附件
     */
    attachment?: string
    /**
     * 附加信息
     */
    extras?: string
    /**
     * 禁用：0=否(默认)，1=是
     */
    isDisable?: boolean
    /**
     * 状态：0=正常(默认)
     */
    status?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
    /**
     * 创建时间：默认为当前时间（自动更新）
     */
    updateTime?: Date
}
export interface FormServiceGeneralFilter {
    /**
     * 发送人
     */
    senderUserId?: number | null
    /**
     * 服务类型id
     */
    serviceBeanId?: number | null
    /**
     * 服务类型
     */
    serviceKey?: string | null
    /**
     * 服务id
     */
    serviceId?: number | null
    /**
     * 用户名
     */
    personName?: string | null
    /**
     * 电话
     */
    linkPhone?: string | null
    /**
     * companyName
     */
    companyName?: string | null
    /**
     * 邮箱
     */
    email?: string | null
    /**
     * 职位
     */
    position?: string | null
}
// API URL： /api/v1/management/form-service-general
// 管理端-表单相关 | 管理端-一般服务表单
export interface IFormServiceGeneral {
    // 获取 一般服务表单列表
    // GET("/list")
    getList(pageParams: PageParams, filter: FormServiceGeneralFilter): Promise<RespBodyPager<FormServiceGeneralModel>>
    // 获取 一般服务表单详情
    // GET("/{id}")
    get(id: number): Promise<RespBody<FormServiceGeneralModel>>
    // 添加 一般服务表单
    // POST
    add(model: FormServiceGeneralModel): Promise<RespBody<boolean>>
    // 修改 一般服务表单
    // PUT
    set(model: FormServiceGeneralModel): Promise<RespBody<boolean>>
    // 删除 一般服务表单, 逗号分割id数组
    // DELETE("/{ids}")
    remove(ids: Array<number>): Promise<RespBody<number>>
}