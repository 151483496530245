import AdminEdit from "@/views/AdminManager/Edit.vue";
import AdminLogEdit from "@/views/AdminLogManager/Edit.vue";
import AdminPowerEdit from "@/views/AdminPowerManager/Edit.vue";
import InformationEdit from "@/views/InformationManager/Edit.vue";
import AppletSettingEdit from "@/views/AppletSettingManager/Edit.vue";
import CompanyEdit from "@/views/CompanyManager/Edit.vue";
import CompanyServBindEdit from "@/views/CompanyServBindManager/Edit.vue";
import FormNeedWhatEdit from "@/views/FormNeedWhatManager/Edit.vue";
import FormServiceGeneralEdit from "@/views/FormServiceGeneralManager/Edit.vue";
import FormServiceJobApplyEdit from "@/views/FormServiceJobApplyManager/Edit.vue";
import FormServiceJobInviteEdit from "@/views/FormServiceJobInviteManager/Edit.vue";
import ServiceActivityEdit from "@/views/ServiceActivityManager/Edit.vue";
import ServiceBeanEdit from "@/views/ServiceBeanManager/Edit.vue";
import ServiceGroupEdit from "@/views/ServiceBeanManager/GroupEdit.vue";
import ServiceGeneralEdit from "@/views/ServiceGeneralManager/Edit.vue";
import ServicePageEdit from "@/views/ServicePageManager/Edit.vue";
import SysLogEdit from "@/views/SysLogManager/Edit.vue";
import UserFavoriteEdit from "@/views/UserFavoriteManager/Edit.vue";
import UserLogEdit from "@/views/UserLogManager/Edit.vue";
import UserEdit from "@/views/UserManager/Edit.vue";
import UserPowerEdit from "@/views/UserPowerManager/Edit.vue";
import SysResourceEdit from "@/views/UserResourceManager/Edit.vue";

const titles = {
    AdminEdit: "管理员",
    AdminLogEdit: "管理员日志",
    AdminPowerEdit: "管理员权限",
    InformationEdit: "首页推荐配置",
    AppletSettingEdit: "小程序设置",
    CompanyEdit: "公司",
    CompanyServBindEdit: "企业服务绑定",
    FormNeedWhatEdit: "用户诉求",
    FormServiceGeneralEdit: "一般服务反馈",
    FormServiceJobApplyEdit: "招聘需求管理",
    FormServiceJobInviteEdit: "职位申请",
    ServiceActivityEdit: "活动服务",
    ServiceBeanEdit: "服务类型",
    ServiceGroupEdit: "服务分组",
    ServiceGeneralEdit: "一般服务",
    ServicePageEdit: "单页服务",
    SysLogEdit: "系统日志",
    SysResourceEdit: "菜单",
    UserFavoriteEdit: "用户收藏",
    UserLogEdit: "用户日志",
    UserEdit: "用户",
    UserPowerEdit: "用户权限"
};

export {
    titles
};

export default {
    install: (app: import("vue").App<any>): void => {
        app.component("AdminEdit", AdminEdit);
        app.component("AdminLogEdit", AdminLogEdit);
        app.component("AdminPowerEdit", AdminPowerEdit);
        app.component("InformationEdit", InformationEdit);
        app.component("AppletSettingEdit", AppletSettingEdit);
        app.component("CompanyEdit", CompanyEdit);
        app.component("CompanyServBindEdit", CompanyServBindEdit);
        app.component("FormNeedWhatEdit", FormNeedWhatEdit);
        app.component("FormServiceGeneralEdit", FormServiceGeneralEdit);
        app.component("FormServiceJobApplyEdit", FormServiceJobApplyEdit);
        app.component("FormServiceJobInviteEdit", FormServiceJobInviteEdit);
        app.component("ServiceActivityEdit", ServiceActivityEdit);
        app.component("ServiceBeanEdit", ServiceBeanEdit);
        app.component("ServiceGroupEdit", ServiceGroupEdit);
        app.component("ServiceGeneralEdit", ServiceGeneralEdit);
        app.component("ServicePageEdit", ServicePageEdit);
        app.component("SysLogEdit", SysLogEdit);
        app.component("UserFavoriteEdit", UserFavoriteEdit);
        app.component("UserLogEdit", UserLogEdit);
        app.component("UserEdit", UserEdit);
        app.component("UserPowerEdit", UserPowerEdit);
        app.component("SysResourceEdit", SysResourceEdit);
    }
};