import { IUserRole, UserRoleModel } from "./interface/IUserRole";
import { RespBody, RespBodyList } from "@/apis/interface/Resp";
import request from "@/utils/request";
// API URL： /api/v1/management/user
// 管理端-用户相关 | 管理端-用户角色
export class ApiUserRole implements IUserRole {

    private path = "/api/v1/management/user-role";

    // 获取 绑定角色列表
    // GET("/list")
    public async getList(userId: number | undefined, roleId: number | undefined): Promise<RespBodyList<UserRoleModel>> {
        const url = `${this.path}/list`;
        const result = await request.get(url, { params: { userId, roleId } });
        console.table(["API@GET@获取 绑定角色列表", this.path, url, result]);
        const ret = result.data as RespBodyList<UserRoleModel>;
        ret.object = ret.object.map((item: any) => new UserRoleModel(item));
        return ret;
    }

    // 修改 角色绑定
    // GET("/update")
    public async update(userId: number, roleId: number, bind: boolean): Promise<RespBody<boolean>> {
        const url = `${this.path}/update`;
        const result = await request.get(url, { params: { userId, roleId, bind } });
        console.table(["API@GET@获取 修改角色绑定", this.path, url, result]);
        const ret = result.data as RespBody<boolean>;
        return ret;
    }
}