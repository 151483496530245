
import { defineComponent, inject, ref, Ref } from "vue";
import { RespBody } from "@/apis/interface/Resp";
import { IUserLog, UserLogModel } from "@/apis/interface/IUserLog";

export default defineComponent({
  props: {},
  setup() {
    const apiUserLog = inject("apiUserLog") as IUserLog;

    let dataList: Ref<UserLogModel[] | null> = ref([]);
    let dataRow: Ref<UserLogModel | null> = ref(null);

    let setData = (list: Ref, row: Ref) => {
      dataList.value = list.value;
      dataRow.value = row.value;
      console.log("--->edit UserLog", dataList, dataRow);
    };

    let submit = async () => {
      if (dataRow.value) {
        let model: UserLogModel = dataRow.value.getFormModel();
        let result = (await apiUserLog.set(model)) as RespBody<boolean>;
        return result.object;
      }
    };

    return {
      dialogWidth: "800px",
      setData,
      submit,
      formModel: dataRow
    };
  }
});
