import { Resp } from "@/apis/interface/Resp";
import { basemodel } from "./basemodel";

export class SysResourceModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("SysResourceModel");
    }
    /**
     * id
     */
    id?: number
    //@=>json(metaData -> '$."name"')
    title?: string;
    /**
     * terminal
     */
    terminal?: string
    /**
     * 父
     */
    parentId?: number
    //@=>parentId=>json(metaData -> '$."name"')
    parentTitle?: string;
    /**
     * 排序
     */
     indexSort?: number
    /**
     * 名称
     */
    name?: string
    /**
     * 路由
     */
    path?: string
    /**
     * 组件
     */
    component?: string
    /**
     * 跳转
     */
    redirect?: string
    /**
     * 数据
     */
    metaData?: string
    /**
     * 禁用：0=否(默认)，1=是
     */
    isDisable?: string
    /**
     * 状态：0=正常(默认)
     */
    status?: string
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
    /**
     * 创建时间：默认为当前时间（自动更新）
     */
    updateTime?: Date

    meta?: any
}

//树形节点
export class SysResourceNodeModel extends SysResourceModel {
    //父节点
    pid?: number | null | undefined
    //子节点
    children?: Array<SysResourceNodeModel> | null | undefined
}

export interface SysResourceFilter {
    /**
     * 终端
     */
    terminal?: string | null
    /**
     * 父
     */
    parentId?: number | null
    /**
     * 角色
     */
    roleIds?: Array<number> | []
}
// API URL： /api/v1/management/sys-resource
// 管理端-系统菜单
export interface ISysResource {
    // 获取菜单树")
    // GET("/tree")
    getMenuTree(terminal: string): Promise<Resp>

    // 根据节点获取下一级列表")
    // GET("/list-sub")
    getList(terminal: string, parentId: number): Promise<Resp>

    // 添加 菜单")
    // POST
    add(model: SysResourceModel): Promise<Resp>

    // 修改 菜单")
    // PUT
    set(model: SysResourceModel): Promise<Resp>

    // 删除 菜单")
    // DELETE
    remove(ids: Array<number>): Promise<Resp>
}