import { IServiceGeneral, ServiceGeneralModel, ServiceGeneralNodeModel, ServiceGeneralFilter } from "./interface/IServiceGeneral";
import { RespBody, RespBodyList, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./interface/PageParams";
import request from "@/utils/request";
// API URL： /api/v1/management/service-general
// 管理端-服务相关 | 管理端-通用树服务
export class ApiServiceGeneral implements IServiceGeneral {

    private path = "/api/v1/management/service-general";

    // 获取公共服务树-目录
    // GET("/tree")
    public async serviceTree(companyId: number, filter: ServiceGeneralFilter): Promise<RespBodyList<ServiceGeneralNodeModel>> {
        const url = `${this.path}/tree`;
        const result = await request.get(url, { params: { companyId, ...filter } });
        console.table(["API@GET@获取公共服务树-目录", this.path, url, companyId, filter, result]);
        const ret = result.data as RespBodyList<ServiceGeneralNodeModel>;
        const recurve = (list: Array<ServiceGeneralNodeModel>) => {
            if (!list || list.length == 0) return [];
            return list.map((item: ServiceGeneralNodeModel) => {
                const children = recurve(item.children as ServiceGeneralNodeModel[]);
                const model = new ServiceGeneralNodeModel(item);
                model.children = children;
                model.pid = item.pid;
                model.name = item.name;
                return model;
            });
        };
        ret.object = recurve(ret.object);
        return ret;
    }
    // 2 获取公共服务树-列表-根据父id
    // GET("/parent-{parentId}/list")
    public async serviceSimpleListByParent(companyId: number, parentId: number): Promise<RespBodyList<ServiceGeneralModel>> {
        const url = `${this.path}/parent-${parentId}/list`;
        const result = await request.get(url, { params: { companyId } });
        console.table(["API@GET@获取公共服务树-列表-根据父id", this.path, url, companyId, parentId, result]);
        const ret = result.data as RespBodyList<ServiceGeneralModel>;
        ret.object = ret.object.map((item: any) => new ServiceGeneralModel(item));
        return ret;
    }
    // 获取 通用树服务列表
    // GET("/list")
    public async getList(companyId: number, pageParams: PageParams, filter: ServiceGeneralFilter): Promise<RespBodyPager<ServiceGeneralModel>> {
        const url = `${this.path}/list`;
        const result = await request.get(url, { params: { companyId, ...pageParams, ...filter } });
        console.table(["API@GET@获取 通用树服务列表", this.path, url, pageParams, filter, result]);
        const ret = result.data as RespBodyPager<ServiceGeneralModel>;
        ret.object.listData = ret.object.listData?.map((item: any) => new ServiceGeneralModel(item));
        return ret;
    }
    // 获取 通用树服务详情
    // GET("/{id}")
    public async get(companyId: number, id: number): Promise<RespBody<ServiceGeneralModel>> {
        const url = `${this.path}/${id}`;
        const result = await request.get(url, { params: { companyId } });
        console.table(["API@GET@获取 通用树服务详情", this.path, url, companyId, id, result]);
        const ret = result.data as RespBody<ServiceGeneralModel>;
        ret.object = new ServiceGeneralModel(ret.object);
        return result.data;
    }
    // 添加 通用树服务
    // POST
    public async add(model: ServiceGeneralModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.post(url, { ...model, children: undefined });
        console.table(["API@POST@添加 通用树服务", this.path, url, model, result]);
        return result.data;
    }
    // 修改 通用树服务
    // PUT
    public async set(model: ServiceGeneralModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.put(url, { ...model, children: undefined });
        console.table(["API@PUT@修改 通用树服务", this.path, url, model, result]);
        return result.data;
    }
    // 删除 通用树服务, 逗号分割id数组
    // DELETE("/{ids}")
    public async remove(ids: Array<number>): Promise<RespBody<number>> {
        const url = `${this.path}/${ids.join(",")}`;
        const result = await request.delete(url);
        console.table(["API@DELETE@删除 通用树服务, 逗号分割id数组", this.path, url, ids, result]);
        return result.data;
    }
}