import { IUserFavorite, UserFavoriteModel, UserFavoriteFilter } from "./interface/IUserFavorite";
import { RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./interface/PageParams";
import request from "@/utils/request";
// API URL： /api/v1/management/user-favorite
// 管理端-用户相关 | 管理端-用户关注
export class ApiUserFavorite implements IUserFavorite {

    private path = "/api/v1/management/user-favorite";

    // 获取用户关注的列表
    // GET("/list")
    public async getList(pageParams: PageParams, filter: UserFavoriteFilter): Promise<RespBodyPager<UserFavoriteModel>> {
        const url = `${this.path}/list-full`;
        const result = await request.get(url, { params: { ...pageParams, ...filter } });
        console.table(["API@GET@获取用户关注的列表", this.path, url, pageParams, filter, result]);
        const ret = result.data as RespBodyPager<UserFavoriteModel>;
        ret.object.listData = ret.object.listData?.map((item: any) => new UserFavoriteModel(item));
        return ret;
    }
}