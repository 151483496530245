
import { defineComponent, ref, Ref, inject } from "vue";
import {
  FormServiceJobInviteModel,
  IFormServiceJobInvite
} from "@/apis/interface/IFormServiceJobInvite";
import { RespBody } from "@/apis/interface/Resp";

export default defineComponent({
  setup() {
    const apiFormServiceJobInvite = inject(
      "apiFormServiceJobInvite"
    ) as IFormServiceJobInvite;

    let dataList: Ref<FormServiceJobInviteModel[] | null> = ref([]);
    let dataRow: Ref<FormServiceJobInviteModel | null> = ref(null);

    let setData = async (list: Ref, row: Ref) => {
      console.log("--->view FormServiceJobInviteModel", dataList, dataRow);
      dataList.value = list.value;
      // dataRow.value = row.value;
      //重新获取最新详细数据
      const ret = (await apiFormServiceJobInvite.get(
        row.value?.id || 0
      )) as RespBody<FormServiceJobInviteModel>;
      dataRow.value = ret.object;
    };

    let statusFormat = (status: number) => {
      return status == 0 ? "待处理" : "已处理";
    };

    return {
      drawerSize: "800px",
      setData,
      statusFormat,
      formModel: dataRow
    };
  }
});
