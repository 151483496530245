
import {
  defineComponent,
  onMounted,
  inject,
  ref,
  Ref,
  computed,
  reactive
} from "vue";
import { RespBody } from "@/apis/interface/Resp";
import {
  IServiceGeneral,
  ServiceGeneralModel
} from "@/apis/interface/IServiceGeneral";
import { List } from "@element-plus/icons";
import { handelImageTag } from "@/utils/tools";

import { ElForm, ElMessage } from "element-plus";
type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  props: {
    data: {
      type: Object
    },
    list: {
      type: List
    }
  },
  setup(props: any) {
    const apiServiceGeneral = inject("apiServiceGeneral") as IServiceGeneral;
    const formRef = ref<FormInstance>();

    let dataList: Ref<ServiceGeneralModel[] | null> = ref([]);
    let dataRow: Ref<ServiceGeneralModel | null> = ref(null);
    let parentDataRow: Ref<ServiceGeneralModel | null> = ref(null);
    let formGroup = reactive({ tab_key: "", tab_name: "" });
    let dialogGroupVisible = ref(0); // null, 0 , 1-新建tab , 2-编辑tab
    let activeTab = ref("first");

    onMounted(() => {
      if (props.data && props.list) setData(props.data, props.list);
    });

    let setData = async (list: Ref, row: Ref) => {
      dataList.value = list.value;
      dataRow.value = row.value;
      console.log("--->edit ServiceGeneralModel", dataList, dataRow);

      if(dataRow.value && !dataRow.value.inTabsJson) {
        dataRow.value.inTabsJson = [];
      }

      if (dataRow.value?.parentId) {
        const ret1 = (await apiServiceGeneral.get(
          0,
          dataRow.value?.parentId || 0
        )) as RespBody<ServiceGeneralModel>;
        parentDataRow.value = ret1.object;
      } else {
        parentDataRow.value = null;
      }
    };

    let isEdit = computed(() => {
      return Boolean(dataRow.value?.id);
    });

    let checkForm = async () => {
      if (!dataRow.value) {
        return null;
      }
      let form = formRef.value;
      if (form) {
        try {
          if (dataRow.value?.nodeType == "file" && !dataRow.value.nodeContent) {
            activeTab.value = "second";
            ElMessage.info("请编辑文件内容");
            return null;
          }
          if (await form.validate()) {
            return dataRow.value.getFormModel() as ServiceGeneralModel;
          }
        } catch (error) {
          console.log(error);
        }
      }
      return null;
    };

    let submit = async () => {
      let model = await checkForm();
      if (model) {
        model.nodeContent = handelImageTag(model.nodeContent);
        switch (isEdit.value) {
          case true: {
            let result = (await apiServiceGeneral.set(
              model
            )) as RespBody<boolean>;
            return result.object;
          }
          case false: {
            let result = (await apiServiceGeneral.add(
              model
            )) as RespBody<boolean>;
            return result.object;
          }
        }
      }
      return false;
    };

    let parentTabListJson = computed(() => {
      return parentDataRow.value?.tabListJson || [];
    });

    let removeTab = (one: any) => {
      if (dataRow.value && dataRow.value.tabListJson)
        dataRow.value.tabListJson = dataRow.value.tabListJson.filter(
          (item) => item.tab_key != one.tab_key
        );
    };

    let editOrAddTab = (one: any) => {
      dialogGroupVisible.value = one ? 2 : 1;
      formGroup.tab_key = one
        ? one.tab_key
        : `TAB-${Math.floor(Math.random() * 1000)}`;
      formGroup.tab_name = one ? one.tab_name : null;
    };

    let submitEditOrAddTab = () => {
      if (dataRow.value) {
        dataRow.value.tabListJson = dataRow.value.tabListJson || [];
        let one = dataRow.value.tabListJson.find(
          (item) => item.tab_key == formGroup.tab_key
        );
        if (one) {
          one.tab_name = formGroup.tab_name;
          one.tab_key = formGroup.tab_key;
        } else {
          dataRow.value.tabListJson.push({ ...formGroup });
        }
      }
    };

    return {
      dialogWidth: "1000px",
      isEdit,
      activeTab,
      dialogGroupVisible,
      parentTabListJson,
      parentDataRow,
      formModel: dataRow,
      formGroup,
      formRef,
      removeTab,
      editOrAddTab,
      submitEditOrAddTab,
      setData,
      submit
    };
  }
});
