import { IAuth, AdminLoginModel } from "./interface/IAuth";
import { RespBody, RespBodyAuth } from "@/apis/interface/Resp";
import request from "@/utils/request";
import { AdminModel } from "./interface/IAdmin";
import { SysResourceNodeModel } from "./interface/ISysResource";
import { SysRoleModel } from "./interface/ISysRole";

// API URL： /api/v1/management/auth
// 登录 用户信息刷新 退出
export class ApiAuth implements IAuth {

    private path = "/api/v1/management/auth";

    private convert(auth: RespBodyAuth): RespBodyAuth {
        auth.object.admin = new AdminModel(auth.object.admin);
        auth.object.meta.roles = auth.object.meta.roles.map(item => new SysRoleModel(item));
        const recurve = (list: Array<SysResourceNodeModel>) => {
            if (!list || list.length == 0) return [];
            return list.map((item: SysResourceNodeModel) => {
                const children = recurve(item.children as SysResourceNodeModel[]);
                const model = new SysResourceNodeModel(item);
                model.children = children;
                model.pid = item.pid;
                model.name = item.name;
                return model;
            });
        };
        auth.object.meta.menus = recurve(auth.object.meta.menus);
        return auth;
    }

    // 登录
    // POST("/login")
    public async login(data: AdminLoginModel): Promise<RespBodyAuth> {
        console.table(data);
        const url = `${this.path}/login`;
        const result = await request.post(url, data);
        const auth = result.data as RespBodyAuth;
        console.table(["API@POST@登录", this.path, url, data, result]);
        return this.convert(auth);
    }
    // 刷新登录信息
    // GET("/login-refresh")
    public async refreshLogin(userId: number): Promise<RespBodyAuth> {
        const url = `${this.path}/login-refresh`;
        const result = await request.get(url, { params: { userId } });
        const auth = result.data as RespBodyAuth;
        console.table(["API@GET@刷新登录信息", this.path, url, userId, result]);
        return this.convert(auth);
    }
    // 退出
    // GET("/logout")
    public async logout(userId: number): Promise<RespBody<any>> {
        const url = `${this.path}/logout`;
        const result = await request.get(url, { params: { userId } });
        console.table(["API@GET@退出", this.path, url, userId, result]);
        return result.data;
    }

    // 获取SCHEMAS
    public async getSchemas(): Promise<RespBody<any>> {
        const url = "/v3/api-docs";
        const result = await request.get(url);
        const schemas = result.data["components"]["schemas"];
        return schemas;
    }
}