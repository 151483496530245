
import {
  defineComponent,
  ref,
  Ref,
  inject,
  computed,
  nextTick,
  onUnmounted,
  onMounted
} from "vue";
import {
  ServiceGeneralModel,
  IServiceGeneral
} from "@/apis/interface/IServiceGeneral";
import { RespBody, RespBodyList } from "@/apis/interface/Resp";
import { ElMessage } from "element-plus";
import bus from "@/utils/bus";
import { useStore } from "@/store/index";
import { IOther } from "@/apis/interface/IOther";
import { setNewOrders, putOrders } from "@/utils/tools";

export default defineComponent({
  props: {
    serviceBeanId: {
      type: Number
    }
  },
  emits: ["on-change"],
  setup(props, { emit }) {
    const {
      state: {
        authData: { admin }
      }
    } = useStore();

    const apiServiceGeneral = inject("apiServiceGeneral") as IServiceGeneral;
    const apiOther = inject("apiOther") as IOther;

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    onMounted(() => {
      bus.on("on-table-row-edit-ok", async () => {
        if (currentNodeModel.value && currentNodeModel.value.id)
          await loadLevelData(currentNodeModel.value.id); //更新子列表
      });
    });

    let expanded = ref(false);
    let dataList: Ref<ServiceGeneralModel[] | null> = ref([]);
    let dataRow: Ref<ServiceGeneralModel | null> = ref(null);
    let parentDataRow: Ref<ServiceGeneralModel | null> = ref(null);
    let levelDataList = ref<ServiceGeneralModel[]>([]);
    let currentNodeModel = ref<ServiceGeneralModel | null>(null);
    let companyId = ref(null);
    let activeTabName = ref("attr");

    let setData = async (list: Ref, row: Ref) => {
      console.log("--->view ServiceGeneralModel", dataList, dataRow);
      dataList.value = list.value;
      dataRow.value = row.value;

      if (dataRow.value?.parentId) {
        const ret1 = (await apiServiceGeneral.get(
          companyId.value || 0,
          dataRow.value?.parentId || 0
        )) as RespBody<ServiceGeneralModel>;
        parentDataRow.value = ret1.object;
      } else {
        parentDataRow.value = null;
      }
    };

    let parentTabListJson = computed(() => {
      return parentDataRow.value?.tabListJson || [];
    });

    let deleteRow = async (id: number) => {
      let result = (await apiServiceGeneral.remove([id])) as RespBody<number>;
      if (result.object > 0) {
        ElMessage({
          type: "success",
          message: "刪除成功！"
        });
        emit("on-change");
        if (currentNodeModel.value && currentNodeModel.value.id)
          await loadLevelData(currentNodeModel.value.id); //更新子列表
      } else
        ElMessage({
          type: "error",
          message: "刪除失敗"
        });
    };

    let loadLevelData = async (parentId: number) => {
      const resp = await apiServiceGeneral.serviceSimpleListByParent(
        companyId.value || 0,
        parentId
      );
      const data = resp as RespBodyList<ServiceGeneralModel>;
      levelDataList.value = data.object;
      console.log("==>", levelDataList);
    };

    let clickEditOrCreate = (option: number) => {
      bus.emit("on-table-row-edit", {
        dataList: [],
        dataRow:
          option == 0
            ? new ServiceGeneralModel({
                serviceBeanId:
                  currentNodeModel.value?.serviceBeanId || props.serviceBeanId,
                parentId: currentNodeModel.value?.id || 0,
                creatorAdminId: admin?.id,
                nodeType: "dir"
              })
            : currentNodeModel.value
      });
    };

    let clickNode = async (model: ServiceGeneralModel) => {
      currentNodeModel.value = model;
      if (model && model.id) {
        levelDataList.value = [];
        if (model.nodeType == "dir") {
          await loadLevelData(model.id);
        } else {
          activeTabName.value = "attr";
        }
        nextTick(() => {
          setData(ref([]), currentNodeModel);
        });
      }
    };

    let statusFormat = (status: number) => {
      // console.log(status);
      return "正常";
    };

    let sortUpDown = async (id: number, type: string) => {
      let ids = setNewOrders(
        levelDataList.value.map((item) => item.id) as Array<number>,
        id,
        type
      );

      if (ids) {
        let result = (await apiOther.sortIndex(
          "service_general",
          ids
        )) as RespBody<number>;

        if (result.object > 0) {
          ElMessage.success("排序操作成功");
          levelDataList.value = putOrders(levelDataList.value, ids);
        }
      } else {
        ElMessage.warning("无法操作排序");
      }
    };

    return {
      drawerSize: "800px",
      expanded,
      companyId,
      parentTabListJson,
      parentDataRow,
      formModel: dataRow,
      currentNodeModel,
      levelDataList,
      activeTabName,
      setData,
      deleteRow,
      statusFormat,
      clickNode,
      clickEditOrCreate,
      sortUpDown
    };
  }
});
