import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (['all', 'prov', 'city', 'county', 'town'].indexOf(_ctx.areaType) >= 0)
      ? (_openBlock(), _createBlock(_component_el_select, {
          key: 0,
          size: "mini",
          style: {"width":"100px"},
          modelValue: _ctx.current.currentProvCode,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.current.currentProvCode) = $event)),
          placeholder: "选择省份"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.provListData, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.code,
                label: item.prov,
                value: item.code
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (['all', 'city', 'county', 'town'].indexOf(_ctx.areaType) >= 0)
      ? (_openBlock(), _createBlock(_component_el_select, {
          key: 1,
          style: {"width":"100px","margin-left":"12px"},
          modelValue: _ctx.current.currentCityCode,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.current.currentCityCode) = $event)),
          placeholder: "选择城市"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cityListData, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.code,
                label: item.city,
                value: item.code
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (['all', 'county', 'town'].indexOf(_ctx.areaType) >= 0)
      ? (_openBlock(), _createBlock(_component_el_select, {
          key: 2,
          style: {"width":"120px","margin-left":"12px"},
          modelValue: _ctx.current.currentCountyCode,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.current.currentCountyCode) = $event)),
          placeholder: "选择区县"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countyListData, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.code,
                label: item.county,
                value: item.code
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (['all', 'town'].indexOf(_ctx.areaType) >= 0)
      ? (_openBlock(), _createBlock(_component_el_select, {
          key: 3,
          style: {"width":"150px","margin-left":"12px"},
          modelValue: _ctx.current.currentTownCode,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.current.currentTownCode) = $event)),
          placeholder: "选择乡镇"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.townListData, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.code,
                label: item.town,
                value: item.code
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}