
const host = process.env.VUE_APP_BASE_API;

import { defineComponent, ref } from "vue";
import type { ElFile } from "element-plus/es/components/upload/src/upload.type";
import { ElMessage } from "element-plus";

export default defineComponent({
  props: {
    path: {
      type: String,
      default: () => ""
    }
  },
  emits: ["on-choose"],
  setup(props, { emit }) {
    let dialogVisible = ref(false);
    let fileUrl = ref("");
    let reqParams = ref({
      path: props.path,
      filename: ""
    });

    let show = () => {
      dialogVisible.value = true;
    };
    const handleAvatarSuccess = (response: any, file: any, fileList: any) => {
      console.log(response, file, fileList);
      fileUrl.value = response.object;
      // fileUrl.value = URL.createObjectURL(file.raw);
    };
    const beforeAvatarUpload = (file: ElFile) => {
      console.log(file.type, file);
      let fileTypes = [
        "application/pdf",
        "text/html",
        "text/plain",
        "image/jpeg",
        "image/png",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ];
      const isRight = fileTypes.indexOf(file.type) >= 0;
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isRight) {
        ElMessage.error("请选择文本或者图片格式文件!");
      }
      if (!isLt2M) {
        ElMessage.error("请选择小于2M的文件!");
      }
      reqParams.value.filename =
        new Date().getTime() + "." + file.name.split(".").pop();
      return isRight && isLt2M;
    };
    let onOkClick = () => {
      emit("on-choose", fileUrl.value);
      dialogVisible.value = false;
      fileUrl.value = "";
    };
    return {
      dialogVisible,
      host,
      fileUrl,
      reqParams,
      show,
      onOkClick,
      handleAvatarSuccess,
      beforeAvatarUpload
    };
  }
});
