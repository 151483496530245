export function handelImageTag(content: string | undefined): string | undefined {
    if (!content) return content;

    const ele = document.createElement("div") as HTMLDivElement;
    ele.innerHTML = content;
    (ele.querySelectorAll("img") || []).forEach((img) => {
        img.style.maxWidth = "100%";
    });
    return ele.innerHTML;
}

export function setNewOrders(ids: Array<number>, id: number, type: string): Array<number> | null {
    const index = ids.indexOf(id);
    
    if (type == "up") {
        if (index > 0) {
            const tmp = ids[index - 1];
            ids[index - 1] = ids[index];
            ids[index] = tmp;
            return ids;
        }
    }

    if (type == "down") {
        if (index < ids.length - 1) {
            const tmp = ids[index + 1];
            ids[index + 1] = ids[index];
            ids[index] = tmp;
            return ids;
        }
    }
    return null;
}

export function putOrders(list: Array<any>, ids: Array<number>): Array<any> {
    const newList = [];
    for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        const one = list.find(item => item.id == id) as any;
        one.indexSort = i + 1;
        newList[i] = one;
    }
    return newList;
}