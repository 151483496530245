
const host = process.env.VUE_APP_BASE_API;

import { defineComponent, ref } from "vue";
import type { ElFile } from "element-plus/es/components/upload/src/upload.type";
import { ElMessage } from "element-plus";

export default defineComponent({
  props: {
    path: {
      type: String,
      default: () => ""
    }
  },
  emits: ["on-choose"],
  setup(props, { emit }) {
    let dialogVisible = ref(false);
    let imageUrl = ref("");
    let reqParams = ref({
      path: props.path,
      filename: ""
    });

    let show = () => {
      dialogVisible.value = true;
    };
    const handleAvatarSuccess = (response: any, file: any, fileList: any) => {
      console.log(response, file, fileList);
      imageUrl.value = response.object;
      // imageUrl.value = URL.createObjectURL(file.raw);
    };
    const beforeAvatarUpload = (file: ElFile) => {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        ElMessage.error("请选择jpg或者png格式的图片!");
      }
      if (!isLt2M) {
        ElMessage.error("请选择小于2M的图片!");
      }
      reqParams.value.filename =
        new Date().getTime() + "." + file.type.split("/")[1];
      return isJPG && isLt2M;
    };
    let onOkClick = () => {
      emit("on-choose", imageUrl.value);
      dialogVisible.value = false;
      imageUrl.value = "";
    };
    return {
      dialogVisible,
      host,
      imageUrl,
      reqParams,
      show,
      onOkClick,
      handleAvatarSuccess,
      beforeAvatarUpload
    };
  }
});
