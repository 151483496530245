import { State, Mutations } from "./IAuthData";
import { MyRoute } from "@/router/IRoute";
import { ActionContext } from "vuex";
import { clone } from "@/utils/clone";
import {
  staticRoutes,
  permissionRoutesLoader
} from "@/router/routes-loader";
import router from "@/router/index";

// 路由懒加载
const loadView = (
  view: string
): (() => Promise<typeof import("*.vue")>) => {
  return (): Promise<typeof import("*.vue")> => import(`@/views/${view}`);
};
//递归处理后端数据
function filterAsyncRouter(asyncRouterMap: Array<MyRoute>): Array<MyRoute> {
  return asyncRouterMap.filter((route: MyRoute) => {
    if (route.children) {
      route.component = () => import("@/layout/Empty.vue");
    } else {
      route.component = loadView(route.component as string);
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    } else {
      delete route["children"];
      delete route["redirect"];
    }
    return true;
  });
}
// 路由重定向 递归为所有有子路由的路由设置第一个children.path为默认路由
function setDefaultRoute(routes: Array<MyRoute>): void {
  routes.forEach((route: MyRoute) => {
    if (route.children && route.children.length > 0) {
      route.redirect = { name: route.children[0].name };
      setDefaultRoute(route.children);
    }
  });
}

const state: State = {
  admin: null,
  roles: [], // 所有路由
  menuRoutes: [], // 导航菜单
  currentMenu: null, // 当前active导航菜单
  crumbList: [] // 面包屑
};

const mutations: Mutations = {
  //设置管理员账号信息
  SET_ADMIN(state, admin: AdminModel) {
    state.admin = admin;
  },
  // 设置权限
  SET_ROLES(state, roles: Array<AdminRoleModel>) {
    state.roles = roles;
  },
  // 设置aside菜单
  SET_MENU_ROUTES(state, menuRoutes: Array<MyRoute>) {
    state.menuRoutes = menuRoutes;
  },
  // 当前选中的菜单
  SET_CURRENT_MENU(state, currentMenu: string) {
    state.currentMenu = currentMenu;
  },
  // 设置面包屑
  SET_CRUMB_LIST(state, crumbList) {
    state.crumbList = crumbList;
  }
};

import authHandler from "@/utils/auth-handler";
import { AdminModel } from "@/apis/interface/IAdmin";
import { AdminRoleModel } from "@/apis/interface/IAdminRole";

const actions = {
  //初始化
  async INITIALIZE({
    commit
  }: ActionContext<unknown, unknown>): Promise<void> {
    const routesContainer = clone(staticRoutes).find((v: any) => v.path === "/"); ////不需要动态的路由（深拷贝）
    routesContainer.children = [...filterAsyncRouter(permissionRoutesLoader()), ...routesContainer.children];//将两种路由结合生成左边的导航栏//处理需要动态的路由 
    /*
        为所有有children的菜单路由设置第一个children为默认路由
        主要是供面包屑用，防止点击面包屑后进入某个路由下的 '' 路由,比如/manage/
        而我们的路由是
        [
            /manage/menu1,
            /manage/menu2
        ]
    */
    setDefaultRoute([routesContainer]);
    router.addRoute(routesContainer);

    commit("SET_MENU_ROUTES", routesContainer.children);
    commit("SET_ROLES", authHandler.getRoles());
    commit("SET_ADMIN", authHandler.getAdmin());

    // /*  初始路由 */
    // const initialRoutes = router.options.routes;
    // /* 完整的路由表 */
    // commit("SET_PERMISSION", [...initialRoutes, ...staticRoutes]);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
