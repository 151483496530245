import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./PageParams";
import { basemodel } from "./basemodel";
export class FormNeedWhatModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("FormNeedWhatModel");
    }
    id?: number
    /**
     * 发送人
     */
    senderUserId?: number
    //@=>senderUserId
    senderUsername?: string
    //@=>senderUserId
    senderRealname?: string
    /**
     * 用户名
     */
    personName?: string
    /**
     * 手机号
     */
    phone?: string
    /**
     * 公司名
     */
    companyName?: string
    /**
     * 邮箱
     */
    email?: string
    /**
     * 职位
     */
    position?: string
    /**
     * 需求
     */
    requirement?: string
    /**
     * 附件
     */
    attachment?: string
    /**
     * 附加信息
     */
    extras?: string
    /**
     * 禁用：0=否(默认)，1=是
     */
    isDisable?: boolean
    /**
     * 状态：0=正常(默认)
     */
    status?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
    /**
     * 创建时间：默认为当前时间（自动更新）
     */
    updateTime?: Date
}
export interface FormNeedWhatFilter {
    /**
     * 发送人
     */
    senderUserId?: number | null
    /**
     * 用户名
     */
    personName?: string | null
    /**
     * 手机号
     */
    phone?: string | null
    /**
     * 公司名
     */
    companyName?: string | null
    /**
     * 邮箱
     */
    email?: string | null
    /**
     * 职位
     */
    position?: string | null
}
// API URL： /api/v1/management/form-need-what
// 管理端-表单相关 | 管理端-诉求管理
export interface IFormNeedWhat {
    // 获取 诉求管理列表
    // GET("/list")
    getList(pageParams: PageParams, filter: FormNeedWhatFilter): Promise<RespBodyPager<FormNeedWhatModel>>
    // 获取 诉求管理详情
    // GET("/{id}")
    get(id: number): Promise<RespBody<FormNeedWhatModel>>
    // 添加 诉求管理
    // POST
    add(model: FormNeedWhatModel): Promise<RespBody<boolean>>
    // 修改 诉求管理
    // PUT
    set(model: FormNeedWhatModel): Promise<RespBody<boolean>>
    // 删除 诉求管理, 逗号分割id数组
    // DELETE("/{ids}")
    remove(ids: Array<number>): Promise<RespBody<number>>
}
