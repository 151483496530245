import { AdminModel } from "@/apis/interface/IAdmin";
import { SysResourceNodeModel } from "@/apis/interface/ISysResource";
import { SysRoleModel } from "@/apis/interface/ISysRole";
import { RespBodyAuth } from "@/apis/interface/Resp";

import { LocalStorage } from "storage-manager-js";

export interface IAuthHandler {

    setAuth(auth: RespBodyAuth): void;

    logOut(): void;

    isLogin(): boolean;

    getToken(): string;

    getAdmin(): AdminModel | null;

    getMenus(): SysResourceNodeModel[];

    getRoles(): SysRoleModel[];
}

export class AuthHandler implements IAuthHandler {

    public setAuth(auth: RespBodyAuth): void {
        console.log("设置登录数据:", auth);
        LocalStorage.set("9028472", auth.object.admin);
        LocalStorage.set("3290130", auth.object.admin.loginToken);
        LocalStorage.set("4515338", auth.object.meta.menus);
        LocalStorage.set("8546913", auth.object.meta.roles);
    }

    public logOut(): void {
        LocalStorage.delete("9028472");
        LocalStorage.delete("3290130");
        LocalStorage.delete("4515338");
        LocalStorage.delete("8546913");
    }

    public isLogin(): boolean {
        return Boolean(this.getToken());
    }

    public getToken(): string {
        return LocalStorage.get("3290130") as string;
    }

    public getAdmin(): AdminModel | null {
        const data = LocalStorage.get("9028472") as any;
        if (data)
            return new AdminModel(data);
        return null;
    }

    public getMenus(): SysResourceNodeModel[] {
        const menus = LocalStorage.get("4515338") as any[];
        const recurve = (list: Array<SysResourceNodeModel>) => {
            if (!list || list.length == 0) return [];
            return list.map((item: SysResourceNodeModel) => {
                const children = recurve(item.children as SysResourceNodeModel[]);
                const model = new SysResourceNodeModel(item);
                model.children = children;
                model.pid = item.pid;
                model.name = item.name;
                return model;
            });
        };
        return recurve(menus);
    }

    public getRoles(): SysRoleModel[] {
        const roles = LocalStorage.get("8546913") as any[];
        return roles.map(item => new SysRoleModel(item));
    }
}

export default new AuthHandler();