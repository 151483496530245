
import { defineComponent, ref, reactive, watchEffect, computed } from "vue";
import AreaSelector from "./AreaSelector.vue";
import { getProv, getCity, getCounty, getTown } from "@/utils/area-res";

export default defineComponent({
  components: { AreaSelector },
  props: {
    areaType: {
      type: String,
      require: true
    },
    currentCode: {
      type: String,
      require: true,
      default: () => ""
    }
  },
  emits: ["update:current-code"],
  setup(props, { emit }) {
    let areaType: string = props.areaType || "";
    let showSelector = ref(false);

    let model = reactive({
      provCode:
        props.currentCode.length >= 2 ? props.currentCode.substr(0, 2) : "",
      cityCode:
        props.currentCode.length >= 4 ? props.currentCode.substr(0, 4) : "",
      countyCode:
        props.currentCode.length >= 6 ? props.currentCode.substr(0, 6) : "",
      townCode:
        props.currentCode.length >= 9 ? props.currentCode.substr(0, 9) : ""
    });

    watchEffect(() => {
      if (areaType == "prov") {
        emit("update:current-code", model.provCode);
      }
      if (areaType == "city") {
        emit("update:current-code", model.cityCode);
      }
      if (areaType == "county") {
        emit("update:current-code", model.countyCode);
      }
      if (areaType == "town") {
        emit("update:current-code", model.townCode);
      }
    });

    let areaName = computed(() => {
      if (areaType == "prov") {
        return getProv(model.provCode)?.prov || "-";
      }
      if (areaType == "city") {
        return getCity(model.cityCode)?.city || "-";
      }
      if (areaType == "county") {
        return getCounty(model.countyCode)?.county || "-";
      }
      if (areaType == "town") {
        return getTown(model.townCode)?.town || "-";
      }
      return "未设定";
    });

    console.table(model);

    return {
      model,
      showSelector,
      areaName
    };
  }
});
