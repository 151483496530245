import { Resp } from "@/apis/interface/Resp";
import { basemodel } from "./basemodel";
import { PageParams } from "./PageParams";
export class UserLogModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("UserLogModel");
    }
    id?: number
    /**
     * 用户id
     */
    userId?: number
    //@=>userId
    username?: string
    //@=>userId
    realname?: string
    /**
     * 操作
     */
    option?: string
    /**
     * 日志消息
     */
    message?: string
    /**
     * 消息级别
     */
    level?: string
    /**
     * 时间：默认为当前时间
     */
    createTime?: Date
}
export interface UserLogFilter {
    /**
     * 用户id
     */
    adminId?: number | null
    /**
     * 操作
     */
    option?: string | null
    /**
     * 日志消息
     */
    message?: string | null
    /**
     * 消息级别：
     */
    level?: string | null
}
// API URL： /api/v1/management/user-log
// 管理端-用户相关 | 管理端-用户日志接口
export interface IUserLog {
    // 获取 用户日志列表
    // GET("/list")
    getList(pageParams: PageParams, filter: UserLogFilter): Promise<Resp>
    // 修改 用户日志
    // PUT
    set(model: UserLogModel): Promise<Resp>
    // 删除 用户日志, 逗号分割id数组
    // DELETE("/{ids}")
    remove(ids: Array<number>): Promise<Resp>
}
