
import { defineComponent, onUpdated, ref } from "vue";

export default defineComponent({
  props: {
    html: {
      type: String,
      default: () => ""
    },
    title: {
      type: String,
      default: () => ""
    }
  },
  setup(props) {
    let content = ref(props.html);

    onUpdated(() => {
      content.value = props.html;
    });

    let showHtml = (d: string) => {
      content.value = d;
      //   dialogVisible.value = true;
    };

    let clickPreview = () => {
      dialogVisible.value = true;
    };

    let dialogVisible = ref(false);
    return {
      dialogVisible,
      content,
      showHtml,
      clickPreview
    };
  }
});
