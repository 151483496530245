import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "iframeID",
  ref: "frame",
  src: "UEditor/editor.html",
  onload: "onframeLoad",
  title: "frame",
  frameborder: "0",
  style: {"width":"930px","height":"750px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", _hoisted_1, null, 512))
}