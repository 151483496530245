<template>
  <router-view />
</template>

<style lang="scss">
.el-drawer__body {
  overflow-y: auto;
}
.el-descriptions__label {
  width: 135px;
}
.el-tree-node {
  &:hover {
    border: gray;
  }
  &.is-current {
    > .el-tree-node__content {
      background: #5252a5 !important;
      color: #ccc;
    }
  }
}
.service-view {
  .service-icon {
    svg {
      width: 50px;
      height: 50px;
    }
  }
  .service-content {
    font-size: 12px;
    background: #e7e8ed;
    border: 1px solid #d1d6ea;
    padding: 12px;
    border-radius: 4px;
    margin-top: 5px;
    &.closed {
      background: #fff;
      max-height: 500px;
      overflow: hidden;
    }
    img {
      width: 100%;
      max-width: 500px;
      display: block;
    }
    @import "@/styles/articel-style.scss";
  }
}
</style>