
import { defineComponent, computed, inject, ref, Ref } from "vue";
import { RespBody } from "@/apis/interface/Resp";
import { IInformation, InformationModel } from "@/apis/interface/IInformation";
import { handelImageTag } from "@/utils/tools";

import { ElForm, ElMessage } from "element-plus";
type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  props: {},
  setup() {
    const apiInformation = inject("apiInformation") as IInformation;
    const formRef = ref<FormInstance>();
    const attachmentBoxRef = ref();

    let dataList: Ref<InformationModel[] | null> = ref([]);
    let dataRow: Ref<InformationModel | null> = ref(null);
    let activeTab = ref("first");

    let setData = (list: Ref, row: Ref) => {
      dataList.value = list.value;
      dataRow.value = row.value;
      console.log("--->edit InformationModel", dataList, dataRow);
    };

    let isEdit = computed(() => {
      return Boolean(dataRow.value?.id);
    });

    let checkForm = async () => {
      if (!dataRow.value) {
        return null;
      }
      let form = formRef.value;
      if (form) {
        try {
          if (!dataRow.value.content) {
            activeTab.value = "second";
            ElMessage.info("请编辑文件内容");
            return null;
          }
          // let attachmentValide = attachmentBoxRef.value.isValide();
          // if (!attachmentValide) {
          //   activeTab.value = "third";
          //   ElMessage.info("附件信息没有完成");
          //   return null;
          // }
          if (await form.validate()) {
            return dataRow.value.getFormModel() as InformationModel;
          }
        } catch (error) {
          console.log(error);
        }
      }
      return null;
    };

    let submit = async () => {
      let model = await checkForm();
      if (model) {
        model.content = handelImageTag(model.content);
        switch (isEdit.value) {
          case true: {
            let result = (await apiInformation.set(model)) as RespBody<boolean>;
            return result.object;
          }
          case false: {
            let result = (await apiInformation.add(model)) as RespBody<boolean>;
            return result.object;
          }
        }
      }
      return false;
    };

    return {
      dialogWidth: "1000px",
      isEdit,
      activeTab,
      setData,
      submit,
      formModel: dataRow,
      formRef,
      attachmentBoxRef
    };
  }
});
