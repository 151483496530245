
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: () => "300px"
    },
    path: {
      type: String,
      default: () => ""
    },
    src: { type: String },
    editable: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ["update:src"],
  setup(props, { emit }) {
    let uploader = ref(null);

    let imageSrc = ref(props.src);

    // onUpdated(() => {
    //   imageSrc.value = props.src;
    // });

    let onChooseFile = (url: string) => {
      imageSrc.value = url;
      emit("update:src", imageSrc.value);
    };

    return {
      editableValue: props.editable,
      imageSrc,
      uploader,
      onChooseFile
    };
  }
});
