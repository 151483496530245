import { IServiceGroup, ServiceGroupModel } from "./interface/IServiceGroup";
import { RespBody, RespBodyList } from "@/apis/interface/Resp";
import request from "@/utils/request";
// API URL： /api/v1/management/service-group
// 管理端-服务相关 | 管理端-服务分组
export class ApiServiceGroup implements IServiceGroup {

    private path = "/api/v1/management/service-group";

    // 获取 服务分组列表
    // GET("/all")
    public async getAll(): Promise<RespBodyList<ServiceGroupModel>> {
        const url = `${this.path}/all`;
        const result = await request.get(url);
        console.table(["API@GET@获取 服务分组列表", this.path, url, result]);
        const ret = result.data as RespBodyList<ServiceGroupModel>;
        ret.object = ret.object.map((item: any) => new ServiceGroupModel(item));
        return ret;
    }
    // 添加 服务分组
    // POST
    public async add(model: ServiceGroupModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.post(url,  { ...model } );
        console.table(["API@POST@添加 服务分组", this.path, url, model, result]);
        return result.data;
    }
    // 修改 服务分组
    // PUT
    public async set(model: ServiceGroupModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.put(url,  { ...model } );
        console.table(["API@PUT@修改 服务分组", this.path, url, model, result]);
        return result.data;
    }
}