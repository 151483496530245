
import { defineComponent, ref, Ref, inject } from "vue";
import {
  FormServiceGeneralModel,
  IFormServiceGeneral
} from "@/apis/interface/IFormServiceGeneral";

export default defineComponent({
  setup() {
    const apiFormServiceGeneral = inject(
      "apiFormServiceGeneral"
    ) as IFormServiceGeneral;

    let dataList: Ref<FormServiceGeneralModel[] | null> = ref([]);
    let dataRow: Ref<FormServiceGeneralModel | null> = ref(null);

    let setData = async (list: Ref, row: Ref) => {
      console.log("--->view FormServiceGeneralModel", dataList, dataRow);
      dataList.value = list.value;
      // dataRow.value = row.value;
      //重新获取最新详细数据
      const ret = await apiFormServiceGeneral.get(row.value?.id || 0);
      dataRow.value = ret.object;
    };

    let statusFormat = (status: number) => {
      return status == 0 ? "待处理" : "已处理";
    };

    return {
      drawerSize: "800px",
      setData,
      statusFormat,
      formModel: dataRow
    };
  }
});
