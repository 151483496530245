import { IInformation, InformationModel, InformationFilter } from "./interface/IInformation";
import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./interface/PageParams";
import request from "@/utils/request";
// API URL： /api/v1/management/information
// 管理端-信息
export class ApiInformation implements IInformation {

    private path = "/api/v1/management/information";

    // 获取 信息列表
    // GET("/list")
    public async getList(pageParams: PageParams, filter: InformationFilter): Promise<RespBodyPager<InformationModel>> {
        const url = `${this.path}/list`;
        const result = await request.get(url, { params: { ...pageParams, ...filter } });
        console.table(["API@GET@获取 信息列表", this.path, url, pageParams, filter, result]);
        const ret = result.data as RespBodyPager<InformationModel>;
        ret.object.listData = ret.object.listData?.map((item: any) => new InformationModel(item));
        return ret;
    }
    // 获取 信息详情
    // GET("/{id}")
    public async get(id: number): Promise<RespBody<InformationModel>> {
        const url = `${this.path}/${id}`;
        const result = await request.get(url);
        console.table(["@API@GET@获取 信息详情", this.path, url, id, result]);
        const ret = result.data as RespBody<InformationModel>;
        ret.object = new InformationModel(ret.object);
        return result.data;
    }
    // 添加 信息
    // POST
    public async add(model: InformationModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.post(url,  { ...model } );
        console.table(["API@POST@添加 信息", this.path, url, model, result]);
        return result.data;
    }
    // 修改 信息
    // PUT
    public async set(model: InformationModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.put(url,  { ...model } );
        console.table(["API@PUT@修改 信息", this.path, url, model, result]);
        return result.data;
    }
    // 删除 信息, 逗号分割id数组
    // DELETE("/{ids}")
    public async remove(ids: Array<number>): Promise<RespBody<number>> {
        const url = `${this.path}/${ids.join(",")}`;
        const result = await request.delete(url);
        console.table(["API@DELETE@删除 信息, 逗号分割id数组", this.path, url, ids, result]);
        return result.data;
    }
}