
import { defineComponent, ref, Ref } from "vue";
import { AdminModel } from "@/apis/interface/IAdmin";

export default defineComponent({
  setup() {
    let dataList: Ref<AdminModel[] | null> = ref([]);
    let dataRow: Ref<AdminModel | null> = ref(null);

    let setData = async (list: Ref, row: Ref) => {
      dataList.value = list.value;
      dataRow.value = row.value;
      console.log("--->view AdminModel", dataList, dataRow);
    };

    let statusFormat = (status: number) => {
      // console.log(status);
      return "正常";
    };

    return {
      drawerSize: "800px",
      setData,
      statusFormat,
      dataRow
    };
  }
});
