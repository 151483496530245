
import {
  defineComponent,
  ref,
  watch,
  onMounted,
  inject,
  SetupContext,
  onUpdated
} from "vue";

import { RespBodyList } from "@/apis/interface/Resp";
import { ServiceGroupModel } from "@/apis/interface/IServiceGroup";
import { IServiceGroup } from "@/apis/interface/IServiceGroup";

export default defineComponent({
  props: {
    serviceGroupId: { type: Number, default: () => undefined }
  },
  emits: ["update:service-group-id"],
  setup(props, context: SetupContext) {
    const apiServiceGroup = inject("apiServiceGroup") as IServiceGroup;
    let beanId = ref<number | undefined>(props.serviceGroupId);
    const loading = ref<boolean>(false);

    onUpdated(() => {
      beanId.value = props.serviceGroupId;
    });

    let dataList = ref<ServiceGroupModel[]>([]);

    let loadData = async () => {
      loading.value = true;
      const resp =
        (await apiServiceGroup.getAll()) as RespBodyList<ServiceGroupModel>;
      dataList.value = resp.object;
      loading.value = false;
    };

    watch(beanId, (n) => {
      context.emit("update:service-group-id", n);
    });

    onMounted(() => {
      loadData();
    });

    return {
      loading,
      groupOptions: dataList,
      beanId
    };
  }
});
