import {
    SysCityModel,
    SysCountyModel,
    SysProvModel,
    SysTownModel
} from "@/apis/interface/ISystem";
import { LocalStorage } from "storage-manager-js";

declare let window: any;

export function provCityCountyTownList(): { provList: Array<SysProvModel>, cityList: Array<SysCityModel>, countyList: Array<SysCountyModel>, townList: Array<SysTownModel> } {

    if (!window.prov_city_county_town_list) {
        window.prov_city_county_town_list = LocalStorage.get("prov_city_county_town_list");
    }

    return window.prov_city_county_town_list as { provList: Array<SysProvModel>, cityList: Array<SysCityModel>, countyList: Array<SysCountyModel>, townList: Array<SysTownModel> };
}

export function getProv(provCode: string | undefined): SysProvModel | undefined {
    return provCityCountyTownList().provList.find(item => item.code == provCode);
}

export function getCity(cityCode: string | undefined): SysCityModel | undefined {
    return provCityCountyTownList().cityList.find(item => item.code == cityCode);
}

export function getCounty(countyCode: string | undefined): SysCountyModel | undefined {
    return provCityCountyTownList().countyList.find(item => item.code == countyCode);
}

export function getTown(townCode: string | undefined): SysTownModel | undefined {
    return provCityCountyTownList().townList.find(item => item.code == townCode);
}

export function getProvList(): Array<SysProvModel> {
    return provCityCountyTownList().provList;
}

export function getCityList(provCode: string | undefined): Array<SysCityModel> {
    return provCityCountyTownList().cityList.filter(item => item.provCode == provCode);
}

export function getCountyList(cityCode: string | undefined): Array<SysCountyModel> {
    return provCityCountyTownList().countyList.filter(item => item.cityCode == cityCode);
}

export function getTownList(countyCode: string | undefined): Array<SysTownModel> {
    return provCityCountyTownList().townList.filter(item => item.countyCode == countyCode);
}