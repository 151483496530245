
import { defineComponent, ref, Ref } from "vue";
import { ServiceBeanModel } from "@/apis/interface/IServiceBean";

export default defineComponent({
  setup() {
    let dataList: Ref<ServiceBeanModel[] | null> = ref([]);
    let dataRow: Ref<ServiceBeanModel | null> = ref(null);

    let setData = async (list: Ref, row: Ref) => {
      console.log("--->view ServiceBeanModel", dataList, dataRow);
      dataList.value = list.value;
      dataRow.value = row.value;
    };
    return {
      drawerSize: "800px",
      setData,
      formModel: dataRow,
    };
  },
});
