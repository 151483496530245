
import moment from "moment";
import { defineComponent, ref } from "vue";
import { ElMessage } from "element-plus";

export default defineComponent({
  props: {
    path: {
      type: String,
      default: () => "files"
    },
    attachmentData: {
      type: Array,
      default: () => {
        return [
          //   {
          //     name: "附件1",
          //     file: "https://",
          //     createTime: "",
          //   }
        ];
      }
    },
    editable: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ["update:attachment-data"],
  setup(props, { emit }) {
    let tableData = ref([...props.attachmentData]);

    let uploader = ref(null);
    let currentRow: any = null;

    let setNew = () => {
      tableData.value.push({
        name: "",
        file: "",
        createTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        opt: "create"
      });
    };

    let onChooseFile = (url: string) => {
      currentRow.file = url;
    };

    let doUpload = (row: any) => {
      currentRow = row;
      (uploader.value as any).show();
    };

    let confirm = () => {
      for (let i of tableData.value) {
        let item: any = i;
        if (item.file.length > 0 && item.name.length > 0) {
          item.opt = undefined;
        } else {
          ElMessage({
            type: "warning",
            message: "表单填写不完整"
          });
          return;
        }
      }
      emit("update:attachment-data", tableData.value);
    };

    // let message = ref("");
    //     let isValide = ()=>{
    // debugger;
    //       let arr = tableData.value as Array<any> | undefined;
    //       if (!arr || !arr.filter) return true;
    // debugger;
    //       let result = arr.filter((item: any) => Boolean(item.opt)).length > 0;
    // debugger;
    //       message.value = result ? "" : "附件信息不完整";
    //       return result;
    //     };

    return {
      editableValue: props.editable,
      tableData,
      uploader,
      setNew,
      doUpload,
      confirm,
      onChooseFile
      // isValide,
      // message
    };
  }
});
