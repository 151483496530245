import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_imager = _resolveComponent("imager")!
  const _component_service_group_selector = _resolveComponent("service-group-selector")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.formModel)
      ? (_openBlock(), _createBlock(_component_el_form, {
          key: 0,
          ref: "formRef",
          size: "mini",
          "label-position": "left",
          "label-width": "100px",
          model: _ctx.formModel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "服务KEY",
              prop: "serviceKey",
              rules: [{ required: true, message: '请填写服务KEY', trigger: 'blur' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  style: {"width":"250px"},
                  modelValue: _ctx.formModel.serviceKey,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.serviceKey) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "服务名称",
              prop: "serviceTitle",
              rules: [
          { required: true, message: '请填写服务名称', trigger: 'blur' },
          {
            min: 2,
            message: '长度不少于2个字符',
            trigger: 'change',
          },
        ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  style: {"width":"150px"},
                  placeholder: "输入服务名称",
                  modelValue: _ctx.formModel.serviceTitle,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formModel.serviceTitle) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "图标" }, {
              default: _withCtx(() => [
                _createVNode(_component_imager, {
                  src: _ctx.formModel.serviceIcon,
                  "onUpdate:src": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.serviceIcon) = $event)),
                  path: "icon-service",
                  editable: ""
                }, null, 8, ["src"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "服务介绍",
              prop: "serviceIntro",
              rules: [
          { required: true, message: '请填写服务介绍', trigger: 'blur' },
          {
            min: 2,
            message: '长度不少于2个字符',
            trigger: 'change',
          },
        ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  placeholder: "输入服务介绍",
                  type: "textarea",
                  autosize: { minRows: 5, maxRows: 10 },
                  modelValue: _ctx.formModel.serviceIntro,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formModel.serviceIntro) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "分组",
              prop: "groupId",
              rules: [{ required: true, message: '请选择分组', trigger: 'blur' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_service_group_selector, {
                  "service-group-id": _ctx.formModel.groupId,
                  "onUpdate:service-group-id": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formModel.groupId) = $event))
                }, null, 8, ["service-group-id"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "文件夹风格",
              prop: "dirStyle",
              rules: [
          { required: true, message: '输入文件夹风格', trigger: 'blur' },
        ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  style: {"width":"100px"},
                  placeholder: "选择文件夹风格",
                  modelValue: _ctx.formModel.dirStyle,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formModel.dirStyle) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "排序" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  style: {"width":"80px"},
                  placeholder: "排序",
                  modelValue: _ctx.formModel.indexSort,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formModel.indexSort) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"]))
      : _createCommentVNode("", true)
  ]))
}