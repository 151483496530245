import {
    IEnum, IndustryEnumModel
} from "./interface/IEnum";
import { RespBody } from "@/apis/interface/Resp";
import request from "@/utils/request";
// API URL： /api/v1/management/system
// 管理端-系统接口
export class ApiEnum implements IEnum {

    private path = "/api/v1/management/feature";

    
    // 获取 行业枚举 列表
    // GET("/industry")
    public async industryEnumList(): Promise<RespBody<Array<IndustryEnumModel>>> {
        const url = `${this.path}/industry`;
        const result = await request.get(url);
        console.table(["API@GET@获取 行业枚举 列表", this.path, url, result]);
        return result.data;
    }
    
}