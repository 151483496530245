
import {
  onMounted,
  defineComponent,
  inject,
  reactive,
  ref,
  watch,
  onUpdated
} from "vue";
import { RespBodyList } from "@/apis/interface/Resp";
import {
  ICompany,
  CompanySearch,
  CompanyModel
} from "@/apis/interface/ICompany";

export default defineComponent({
  props: {
    companyIdSelected: {
      type: Number,
      default: () => undefined
    }
  },
  emits: ["update:company-id-selected"],
  setup(props, { emit }) {
    const apiCompany = inject("apiCompany") as ICompany;
    let currentCompanyId = ref<number | undefined>(
      props.companyIdSelected || undefined
    );

    onUpdated(() => {
      currentCompanyId.value = props.companyIdSelected || undefined;
      search.id = props.companyIdSelected || undefined;
      // loadData(search);
    });

    let search = reactive<CompanySearch>({
      id: props.companyIdSelected,
      key: "",
      size: 20
    });

    let dataList = ref<CompanyModel[]>([]);

    let loadData = async (search: CompanySearch) => {
      loading.value = true;
      const resp = await apiCompany.getListSearch(search);
      const pager: RespBodyList<CompanyModel> = resp;
      let list: Array<CompanyModel> = pager.object;
      dataList.value = [
        new CompanyModel({ id: 0, companyName: "下拉选择企业（支持搜索）" }),
        ...list
      ];
      loading.value = false;
    };

    watch(currentCompanyId, (n: number | undefined) => {
      emit("update:company-id-selected", n);
    });

    let remoteSearchMethod = (key: string) => {
      search.id = undefined;
      search.key = key;
      loadData(search);
    };

    onMounted(() => {
      loadData(search);
    });

    const loading = ref(false);
    return {
      currentCompanyId,
      dataList,
      loading,
      remoteSearchMethod
    };
  }
});
