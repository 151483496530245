
import { defineComponent, computed, inject, ref, Ref, watch } from "vue";
import { RespBody } from "@/apis/interface/Resp";
import {
  IServiceActivity,
  ServiceActivityModel,
} from "@/apis/interface/IServiceActivity";
import { handelImageTag } from "@/utils/tools";

import { ElForm, ElMessage } from "element-plus";
type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  props: {},
  setup() {
    const apiServiceActivity = inject("apiServiceActivity") as IServiceActivity;
    const formRef = ref<FormInstance>();
    const attachmentBoxRef = ref();

    let dataList: Ref<ServiceActivityModel[] | null> = ref([]);
    let dataRow: Ref<ServiceActivityModel | null> = ref(null);
    let activeTab = ref("first");
    let formDatetime = ref([new Date(), new Date()]);

    let setData = (list: Ref, row: Ref) => {
      dataList.value = list.value;
      dataRow.value = row.value;
      formDatetime.value = [
        dataRow.value?.actTime || new Date(),
        dataRow.value?.actTimeEnd || new Date(),
      ];
      console.log("--->edit ServiceActivityModel", dataList, dataRow);
    };

    watch(formDatetime, (v) => {
      if (dataRow.value) {
        dataRow.value.actTime = v[0] as Date;
        dataRow.value.actTimeEnd = v[1] as Date;
        console.table(dataRow.value);
      }
    });

    let isEdit = computed(() => {
      return Boolean(dataRow.value?.id);
    });

    let checkForm = async () => {
      if (!dataRow.value) {
        return null;
      }
      let form = formRef.value;
      if (form) {
        try {
          if (!dataRow.value.content) {
            activeTab.value = "second";
            ElMessage.info("请编辑文件内容");
            return null;
          }
          // let attachmentValide = attachmentBoxRef.value.isValide();
          // if (!attachmentValide) {
          //   activeTab.value = "third";
          //   ElMessage.info("附件信息没有完成");
          //   return null;
          // }
          if (await form.validate()) {
            return dataRow.value.getFormModel() as ServiceActivityModel;
          }
        } catch (error) {
          console.log(error);
        }
      }
      return null;
    };

    let submit = async () => {
      let model = await checkForm();
      if (model) {
        model.content = handelImageTag(model.content);
        switch (isEdit.value) {
          case true: {
            let result = (await apiServiceActivity.set(
              model
            )) as RespBody<boolean>;
            return result.object;
          }
          case false: {
            let result = (await apiServiceActivity.add(
              model
            )) as RespBody<boolean>;
            return result.object;
          }
        }
      }
      return false;
    };

    return {
      dialogWidth: "1000px",
      activeTab,
      isEdit,
      setData,
      submit,
      formModel: dataRow,
      formDatetime,
      formRef,
      attachmentBoxRef,
    };
  },
});
