import { ISysResource, SysResourceModel, SysResourceNodeModel } from "./interface/ISysResource";
import { RespBody, RespBodyList } from "@/apis/interface/Resp";
import request from "@/utils/request";
// API URL： /api/v1/management/sys-resource
// 管理端-系统菜单
export class ApiSysResource implements ISysResource {

    private path = "/api/v1/management/sys-resource";

    // 获取菜单树
    // GET("/tree")
    public async getMenuTree(terminal: string): Promise<RespBody<Array<SysResourceModel>>> {
        const url = `${this.path}/tree`;
        const result = await request.get(url, { params: { terminal } });
        console.table(["API@GET@获取菜单树", this.path, url, result]);
        const ret = result.data as RespBodyList<SysResourceNodeModel>;
        const recurve = (list: Array<SysResourceNodeModel>) => {
            if (!list || list.length == 0) return [];
            return list.map((item: SysResourceNodeModel) => {
                const children = recurve(item.children as SysResourceNodeModel[]);
                const model = new SysResourceNodeModel(item);
                model.children = children;
                model.pid = item.pid;
                model.name = item.name;
                return model;
            });
        };
        ret.object = recurve(ret.object);
        return result.data;
    }

    // 根据节点获取下一级列表
    // GET("/list-sub")
    public async getList(terminal: string, parentId: number): Promise<RespBodyList<SysResourceModel>> {
        const url = `${this.path}/list-sub`;
        const result = await request.get(url, { params: { terminal, parentId } });
        console.table(["API@GET@根据节点获取下一级列表", this.path, url, result]);
        const ret = result.data as RespBodyList<SysResourceModel>;
        ret.object = ret.object.map((item: any) => new SysResourceModel(item));
        return ret;
    }

    // 添加 菜单
    // POST
    public async add(model: SysResourceModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.post(url, { ...model });
        console.table(["API@POST@添加 菜单", this.path, url, result]);
        return result.data;
    }

    // 修改 菜单
    // PUT
    public async set(model: SysResourceModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.put(url, { ...model });
        console.table(["API@PUT@修改 菜单", this.path, url, result]);
        return result.data;
    }

    // 删除 菜单
    // DELETE
    public async remove(ids: Array<number>): Promise<RespBody<number>> {
        const url = `${this.path}/${ids.join(",")}`;
        const result = await request.delete(url);
        console.table(["API@DELETE@删除 菜单", this.path, url, result]);
        return result.data;
    }
}