
// <tab-bar
//   v-model:editable-tabs-value="editableTabsValue"
//   v-model:editable-tabs="editableTabs"
// />
import { defineComponent, PropType } from "vue";
import { ElMessage } from "element-plus";

interface EditableTabs {
  title: string;
  name: string;
  closable?: boolean;
}
export default defineComponent({
  name: "TabBar",
  props: {
    editableTabsValue: {
      type: String,
      default: () => ""
    },
    editableTabs: {
      type: Array as PropType<EditableTabs[]>,
      default: (): EditableTabs[] => []
    }
  },
  setup(props, { emit }) {
    // 改变
    const changeValue = (e: string) => {
      emit("update:editable-tabs-value", e); // 改变v-model的值
    };
    // 删除
    const tabRemove = (targetName: string) => {
      const tabs = props.editableTabs;
      if (!tabs || tabs.length <= 1) {
        // 如果不可被关闭
        ElMessage({
          type: "warning",
          message: "当前标签不可被关闭"
        });
        return true;
      }
      if (props.editableTabsValue === targetName) {
        // 点击的是当前项
        tabs.some((tab, index) => {
          if (tab.name === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              emit("update:editable-tabs-value", nextTab.name);
              return true;
            }
          }
          return false;
        });
      }

      emit(
        "update:editable-tabs",
        tabs.filter((tab) => tab.name !== targetName)
      );
    };
    return {
      changeValue,
      tabRemove
    };
  }
});
