
import { defineComponent, reactive, ref } from "vue";
let formRes = require("@/utils/form-res.json");

export default defineComponent({
  name: "FormtypeSelector",
  props: {
    formSelected: {
      type: String,
      default: () => ""
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ["update:form-selected"],
  setup(props, { emit }) {
    let currentForms = ref<Array<string>>(
      props.formSelected ? props.formSelected.split(",") : []
    );

    let onSelectChange = (v: Array<any>) => {
      currentForms.value = v;
      emit("update:form-selected", v.join(","));
    };

    let getNames = () => {
      return currentForms.value
        .map((item: string) => formRes[item] || item)
        .join(",");
    };

    return {
      currentForms,
      getNames,
      formMap: reactive(formRes),
      onSelectChange
    };
  }
});
