
import { Delete, Edit, View, InfoFilled } from "@element-plus/icons";
import bus from "@/utils/bus";
import { defineComponent, toRefs } from "vue";
export default defineComponent({
  props: {
    disableButtons: {
      type: Array
    },
    dataList: {
      type: Array
    },
    dataRow: {
      type: Object
    }
  },
  emits: ["table-row-delete"],
  setup(props, { emit }) {
    const { dataList, dataRow } = toRefs(props);

    let editObject = () => {
      console.log("编辑");
      bus.emit("on-table-row-edit", {
        dataList: dataList.value,
        dataRow: dataRow.value
      });
    };

    let deleteObject = () => {
      emit("table-row-delete", dataRow.value?.id);
    };

    let showObject = () => {
      console.log("修改");
      bus.emit("on-table-row-view", {
        dataList: dataList.value,
        dataRow: dataRow.value
      });
    };

    let isDisable = (type: string) => {
      if (!type) {
        return false;
      }
      let list = (props.disableButtons || []) as Array<string>;
      return list.indexOf(type) >= 0;
    };

    return {
      editObject,
      deleteObject,
      showObject,
      isDisable,
      icon: {
        delete: Delete,
        edit: Edit,
        view: View,
        infoFilled: InfoFilled
      }
    };
  }
});
