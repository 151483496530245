import { IFormServiceGeneral, FormServiceGeneralModel, FormServiceGeneralFilter } from "./interface/IFormServiceGeneral";
import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./interface/PageParams";
import request from "@/utils/request";
// API URL： /api/v1/management/form-service-general
// 管理端-表单相关 | 管理端-一般服务表单
export class ApiFormServiceGeneral implements IFormServiceGeneral {

    private path = "/api/v1/management/form-service-general";

    // 获取 一般服务表单列表
    // GET("/list")
    public async getList(pageParams: PageParams, filter: FormServiceGeneralFilter): Promise<RespBodyPager<FormServiceGeneralModel>> {
        const url = `${this.path}/list`;
        const result = await request.get(url, { params: { ...pageParams, ...filter } });
        console.table(["API@GET@获取 一般服务表单列表", this.path, url, pageParams, filter, result]);
        const ret = result.data as RespBodyPager<FormServiceGeneralModel>;
        ret.object.listData = ret.object.listData?.map((item: any) => new FormServiceGeneralModel(item));
        return ret;
    }
    // 获取 一般服务表单详情
    // GET("/{id}")
    public async get(id: number): Promise<RespBody<FormServiceGeneralModel>> {
        const url = `${this.path}/${id}`;
        const result = await request.get(url);
        console.table(["API@GET@获取 一般服务表单详情", this.path, url, id, result]);
        const ret = result.data as RespBody<FormServiceGeneralModel>;
        ret.object = new FormServiceGeneralModel(ret.object);
        return result.data;
    }
    // 添加 一般服务表单
    // POST
    public async add(model: FormServiceGeneralModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.post(url,  { ...model } );
        console.table(["API@POST@添加 一般服务表单", this.path, url, model, result]);
        return result.data;
    }
    // 修改 一般服务表单
    // PUT
    public async set(model: FormServiceGeneralModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.put(url,  { ...model } );
        console.table(["API@PUT@修改 一般服务表单", this.path, url, model, result]);
        return result.data;
    }
    // 删除 一般服务表单, 逗号分割id数组
    // DELETE("/{ids}")
    public async remove(ids: Array<number>): Promise<RespBody<number>> {
        const url = `${this.path}/${ids.join(",")}`;
        const result = await request.delete(url);
        console.table(["API@DELETE@删除 一般服务表单, 逗号分割id数组", this.path, url, ids, result]);
        return result.data;
    }
}