import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "data-id" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_service_bean_selector = _resolveComponent("service-bean-selector")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_admin_selector = _resolveComponent("admin-selector")!
  const _component_formtype_selector = _resolveComponent("formtype-selector")!
  const _component_icon_lock = _resolveComponent("icon-lock")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_icon_sunny = _resolveComponent("icon-sunny")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_editor = _resolveComponent("editor")!
  const _component_preview = _resolveComponent("preview")!
  const _component_attachment_box = _resolveComponent("attachment-box")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_ctx.formModel)
    ? (_openBlock(), _createBlock(_component_el_tabs, {
        key: 0,
        modelValue: _ctx.activeTab,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.activeTab) = $event)),
        type: "border-card"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            label: "基本信息",
            name: "first"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form, {
                ref: "formRef",
                size: "mini",
                "label-position": "left",
                "label-width": "100px",
                model: _ctx.formModel
              }, {
                default: _withCtx(() => [
                  (_ctx.formModel.id)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 0,
                        label: "#"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.formModel.id), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_form_item, { label: "服务类别" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_service_bean_selector, {
                        "service-type": "page",
                        clearable: false,
                        "service-bean-id": _ctx.formModel.serviceBeanId,
                        "onUpdate:service-bean-id": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.serviceBeanId) = $event))
                      }, null, 8, ["service-bean-id"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { label: "图标" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        placeholder: "图标",
                        modelValue: _ctx.formModel.icon,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formModel.icon) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { label: "标题" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        placeholder: "输入标题",
                        modelValue: _ctx.formModel.title,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.title) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { label: "创建者" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_admin_selector, {
                        "admin-id-selected": _ctx.formModel.creatorAdminId,
                        "onUpdate:admin-id-selected": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formModel.creatorAdminId) = $event))
                      }, null, 8, ["admin-id-selected"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { label: "表单" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_formtype_selector, {
                        "form-selected": _ctx.formModel.hasForm,
                        "onUpdate:form-selected": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formModel.hasForm) = $event))
                      }, null, 8, ["form-selected"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { label: "开放" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_radio_group, {
                        modelValue: _ctx.formModel.isOpen,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formModel.isOpen) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_radio, { label: true }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_icon, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_icon_lock)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_radio, { label: false }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_icon, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_icon_sunny)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, {
            label: _ctx.formModel.nodeType == 'dir' ? '目录描述' : '文件描述',
            name: "second"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_editor, {
                "initial-height": 600,
                "text-value": _ctx.formModel.content,
                "onUpdate:text-value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formModel.content) = $event))
              }, null, 8, ["text-value"]),
              _createVNode(_component_preview, {
                title: _ctx.formModel.title,
                html: _ctx.formModel.content
              }, null, 8, ["title", "html"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_tab_pane, {
            label: "附件",
            name: "third"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_attachment_box, {
                ref: "attachmentBoxRef",
                editable: "",
                path: "attachment-service-page",
                "attachment-data": _ctx.formModel.attachmentJson,
                "onUpdate:attachment-data": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formModel.attachmentJson) = $event))
              }, null, 8, ["attachment-data"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}