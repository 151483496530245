import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "imager-uploader" }
const _hoisted_2 = { class: "image-slot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_picture = _resolveComponent("icon-picture")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_image_uploader = _resolveComponent("image-uploader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_image, {
        style: _normalizeStyle({ width: _ctx.width, minHeight: '50px' }),
        src: _ctx.imageSrc,
        fit: "fill"
      }, {
        error: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_icon_picture)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["style", "src"]),
      (_ctx.editableValue)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            class: "upload-button",
            icon: "icon-upload",
            type: "warning",
            circle: "",
            size: "mini",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.uploader.show()))
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_image_uploader, {
      ref: "uploader",
      path: _ctx.path,
      onOnChoose: _ctx.onChooseFile
    }, null, 8, ["path", "onOnChoose"])
  ], 64))
}