import { IndustryEnumModel } from "@/apis/interface/IEnum";
import { LocalStorage } from "storage-manager-js";

declare let window: any;

export function industryEnumList(): Array<IndustryEnumModel> {
  if (!window.industry_enum_list) {
    window.industry_enum_list = LocalStorage.get("industry_enum_list");
  }
  return window.industry_enum_list as Array<IndustryEnumModel>;
}

export function getIndustry(
  enumKey: string | undefined
): IndustryEnumModel | undefined {
  return industryEnumList().find((item) => item.enumKey == enumKey);
}

export function getIndustryTree(): Array<IndustryEnumModel> {
  const list = industryEnumList();
  const tree: any[] = [];
  list.forEach((i) => {
    const { groupName } = i;
    let index = tree.findIndex((item) => item.enumName == groupName);
    if (index < 0) {
      const parent = JSON.parse(JSON.stringify(i));
      parent.enumName = parent.groupName;
      parent.children = [];
      index = tree.push(parent) - 1;
    }
    tree[index].children.push(i);
  });
  return tree as Array<IndustryEnumModel>;
}
