
import { defineComponent, computed, inject, ref, Ref } from "vue";
import { RespBody } from "@/apis/interface/Resp";
import { ICompany, CompanyModel } from "@/apis/interface/ICompany";

import type { ElForm } from "element-plus";
type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  props: {},
  setup() {
    const apiCompany = inject("apiCompany") as ICompany;
    const formRef = ref<FormInstance>();

    let dataList: Ref<CompanyModel[] | null> = ref([]);
    let dataRow: Ref<CompanyModel | null> = ref(null);

    let setData = (list: Ref, row: Ref) => {
      dataList.value = list.value;
      dataRow.value = row.value;
      console.log("--->edit CompanyModel", dataList, dataRow);
    };

    let isEdit = computed(() => {
      return Boolean(dataRow.value?.id);
    });

    let checkForm = async () => {
      let form = formRef.value;
      let valid: boolean | undefined = false;
      if (form) {
        try {
          valid = await form.validate();
          if (valid) {
            return true;
          }
        } catch (error) {
          console.log(error);
        }
      }
      return false;
    };

    let submit = async () => {
      if ((await checkForm()) && dataRow.value) {
        let model: CompanyModel = dataRow.value.getFormModel();
        switch (isEdit.value) {
          case true: {
            let result = (await apiCompany.set(model)) as RespBody<boolean>;
            return result.object;
          }
          case false: {
            let result = (await apiCompany.add(model)) as RespBody<boolean>;
            return result.object;
          }
        }
      }
      return false;
    };

    return {
      dialogWidth: "1000px",
      isEdit,
      setData,
      submit,
      formModel: dataRow,
      formRef
    };
  }
});
