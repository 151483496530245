import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_imager = _resolveComponent("imager")!
  const _component_admin_selector = _resolveComponent("admin-selector")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_editor = _resolveComponent("editor")!
  const _component_preview = _resolveComponent("preview")!
  const _component_attachment_box = _resolveComponent("attachment-box")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_ctx.formModel)
    ? (_openBlock(), _createBlock(_component_el_tabs, {
        key: 0,
        modelValue: _ctx.activeTab,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.activeTab) = $event)),
        type: "border-card"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            label: "基本信息",
            name: "first"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form, {
                ref: "formRef",
                size: "mini",
                "label-position": "left",
                "label-width": "100px",
                model: _ctx.formModel
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    label: "新闻标题",
                    prop: "title",
                    rules: [
            { required: true, message: '请填写标题', trigger: 'blur' },
            {
              min: 2,
              message: '长度不少于2个字符',
              trigger: 'change',
            },
          ]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        placeholder: "输入新闻标题",
                        modelValue: _ctx.formModel.title,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.title) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { label: "图标" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_imager, {
                        src: _ctx.formModel.icon,
                        "onUpdate:src": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formModel.icon) = $event)),
                        path: "icon-information",
                        editable: ""
                      }, null, 8, ["src"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { label: "附加信息" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        placeholder: "输入附加信息",
                        modelValue: _ctx.formModel.extras,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.extras) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { label: "查看次数" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        style: {"width":"80px"},
                        placeholder: "输入查看次数",
                        modelValue: _ctx.formModel.viewCount,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formModel.viewCount) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { label: "创建的管理员" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_admin_selector, {
                        "admin-id-selected": _ctx.formModel.creatorAdminId,
                        "onUpdate:admin-id-selected": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formModel.creatorAdminId) = $event))
                      }, null, 8, ["admin-id-selected"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "源类型",
                    prop: "sourceType",
                    rules: [{ required: true, message: '选择源类型', trigger: 'blur' }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: _ctx.formModel.sourceType,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formModel.sourceType) = $event)),
                        style: {"width":"150px"},
                        placeholder: "源类型",
                        size: "mini"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_option, {
                            label: "法律",
                            value: "law"
                          }),
                          _createVNode(_component_el_option, {
                            label: "活动",
                            value: "activity"
                          }),
                          _createVNode(_component_el_option, {
                            label: "新闻",
                            value: "news"
                          }),
                          _createVNode(_component_el_option, {
                            label: "报告",
                            value: "report"
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "服务KEY",
                    prop: "serviceKey"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        style: {"width":"300px"},
                        placeholder: "选择服务KEY",
                        modelValue: _ctx.formModel.serviceKey,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formModel.serviceKey) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "资源ID",
                    prop: "serviceId"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        style: {"width":"80px"},
                        placeholder: "资源id",
                        modelValue: _ctx.formModel.serviceId,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formModel.serviceId) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, {
            label: _ctx.formModel.nodeType == 'dir' ? '目录描述' : '文件描述',
            name: "second"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_editor, {
                "initial-height": 600,
                "text-value": _ctx.formModel.content,
                "onUpdate:text-value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formModel.content) = $event))
              }, null, 8, ["text-value"]),
              _createVNode(_component_preview, {
                title: _ctx.formModel.title,
                html: _ctx.formModel.content
              }, null, 8, ["title", "html"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_tab_pane, {
            label: "附件",
            name: "third"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_attachment_box, {
                ref: "attachmentBoxRef",
                editable: "",
                path: "attachment-information",
                "attachment-data": _ctx.formModel.attachmentJson,
                "onUpdate:attachment-data": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formModel.attachmentJson) = $event))
              }, null, 8, ["attachment-data"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}