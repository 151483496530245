
import { defineComponent, ref, Ref, inject } from "vue";
import {
  CompanyModel,
  ICompany,
  MoreCompnayInfoModel
} from "@/apis/interface/ICompany";

export default defineComponent({
  setup() {
    const apiCompany = inject("apiCompany") as ICompany;
    let activeNames = "";

    let dataList: Ref<CompanyModel[] | null> = ref([]);
    let dataRow: Ref<CompanyModel | null> = ref(null);
    let moreInfo: Ref<MoreCompnayInfoModel | null> = ref(null);

    let setData = async (list: Ref, row: Ref) => {
      console.log("--->view CompanyModel", dataList, dataRow);
      dataList.value = list.value;
      // dataRow.value = row.value;
      //重新获取最新详细数据
      const ret = await apiCompany.get(row.value?.id || 0);
      dataRow.value = ret.object;
      moreInfo.value = ret.object.detailData || new MoreCompnayInfoModel({});
    };

    let statusFormat = (status: number) => {
      // console.log(status);
      return "正常";
    };

    let handleChange = (val: string) => {
      activeNames = val;
      console.log(activeNames);
    };
    return {
      drawerSize: "800px",
      setData,
      statusFormat,
      formModel: dataRow,
      activeNames,
      handleChange,
      moreInfo
    };
  }
});
