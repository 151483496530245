import { IAdminRole, AdminRoleModel } from "./interface/IAdminRole";
import { RespBody, RespBodyList } from "@/apis/interface/Resp";
import request from "@/utils/request";
// API URL： /api/v1/management/admin-role
// 管理端-用户相关 | 管理端-管理员角色
export class ApiAdminRole implements IAdminRole {

    private path = "/api/v1/management/admin-role";

    // 获取 绑定角色列表
    // GET("/list")
    public async getList(adminId: number, roleId: number): Promise<RespBodyList<AdminRoleModel>> {
        const url = `${this.path}/list`;
        const result = await request.get(url, { params: { adminId, roleId } });
        console.table(["API@GET@获取 绑定角色列表", this.path, url, result]);
        const ret = result.data as RespBodyList<AdminRoleModel>;
        ret.object = ret.object.map((item: any) => new AdminRoleModel(item));
        return ret;
    }

    // 修改 角色绑定
    // GET("/update")
    public async update(adminId: number, roleId: number, bind: boolean): Promise<RespBody<boolean>> {
        const url = `${this.path}/update`;
        const result = await request.get(url, { params: { adminId, roleId, bind } });
        console.table(["API@GET@获取 修改角色绑定", this.path, url, result]);
        const ret = result.data as RespBody<boolean>;
        return ret;
    }
}