
import {
  defineComponent,
  inject,
  Ref,
  ref,
  computed,
  onMounted,
  SetupContext,
  onUpdated,
} from "vue";
import { RespBody } from "@/apis/interface/Resp";
import { IServiceBean, ServiceBeanModel } from "@/apis/interface/IServiceBean";
import { ServiceGroupMenuModel } from "@/apis/interface/IServiceGroup";
import { ElMessage } from "element-plus";

export default defineComponent({
  props: {
    clearable: {
      type: Boolean,
      default: () => false,
    },
    autoOpen: {
      type: Boolean,
      default: () => false,
    },
    serviceBeanId: { type: Number },
    serviceType: { type: String, default: () => "all" },
  },
  emits: ["update:service-bean-id"],
  setup(props, context: SetupContext) {
    const apiServiceBean = inject("apiServiceBean") as IServiceBean;

    let isClearable = ref(props.clearable);
    let dialogVisible = ref<boolean>(props.autoOpen);
    const loading = ref(false);

    let dataList = ref<ServiceGroupMenuModel[]>([]);
    let currentBeanId = ref<number | undefined>(
      props.serviceBeanId || undefined
    );

    onUpdated(() => {
      currentBeanId.value = props.serviceBeanId || undefined;
    });

    let currentBean: Ref<ServiceBeanModel | null> = computed(() => {
      for (let item of dataList.value) {
        let one = item.services.find(
          (item) => item.id == currentBeanId.value
        ) as ServiceBeanModel;
        if (one) return one;
      }
      return null;
    });

    let loadData = async () => {
      loading.value = true;
      const resp = (await apiServiceBean.menus()) as RespBody<
        Array<ServiceGroupMenuModel>
      >;
      dataList.value = resp.object;
      loading.value = false;
    };

    let isEnable = (bean: ServiceBeanModel) => {
      if (props.serviceType == "all") {
        return true;
      }
      return (
        bean &&
        bean.serviceKey &&
        bean.serviceKey.indexOf(`service://${props.serviceType}`) == 0
      );
    };

    let selectBean = (bean: ServiceBeanModel | null) => {
      if (bean == null) {
        dialogVisible.value = false;
        context.emit("update:service-bean-id", null);
        currentBeanId.value = undefined;

        ElMessage({
          type: "info",
          message: "清除了选择",
        });
        return;
      }

      if (isEnable(bean)) {
        dialogVisible.value = false;
        context.emit("update:service-bean-id", bean?.id);
        currentBeanId.value = bean?.id;
      } else {
        ElMessage({
          type: "warning",
          message: "不可选择此类别",
        });
      }
    };

    onMounted(() => {
      loadData();
    });

    return {
      loading,
      isClearable,
      isEnable,
      dataList,
      currentBeanId,
      currentBean,
      dialogVisible,
      selectBean,
    };
  },
});
