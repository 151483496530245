
import { defineComponent, ref, Ref } from "vue";
import { InformationModel } from "@/apis/interface/IInformation";

export default defineComponent({
  setup() {
    let expanded = ref(false);
    let dataList: Ref<InformationModel[] | null> = ref([]);
    let dataRow: Ref<InformationModel | null> = ref(null);

    let setData = async (list: Ref, row: Ref) => {
      console.log("--->view InformationModel", dataList, dataRow);
      dataList.value = list.value;
      dataRow.value = row.value;
      //重新获取最新详细数据
      // const ret = (await apiInformation.get(
      //   row.value?.id || 0
      // )) as RespBody<InformationModel>;
      // dataRow.value = ret.object;
    };
    return {
      drawerSize: "800px",
      expanded,
      setData,
      formModel: dataRow
    };
  }
});
