import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    size: "mini",
    disabled: _ctx.disabled,
    modelValue: _ctx.currentForms,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentForms) = $event)),
    multiple: "",
    placeholder: "选择表单类型",
    style: {"width":"350px"},
    onChange: _ctx.onSelectChange
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formMap, (v, k) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: k,
          label: `${v} [${k}]`,
          value: k
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["disabled", "modelValue", "onChange"]))
}