
import { defineComponent, ref, Ref, inject } from "vue";
import {
  FormNeedWhatModel,
  IFormNeedWhat
} from "@/apis/interface/IFormNeedWhat";

export default defineComponent({
  setup() {
    const apiFormNeedWhat = inject("apiFormNeedWhat") as IFormNeedWhat;

    let dataList: Ref<FormNeedWhatModel[] | null> = ref([]);
    let dataRow: Ref<FormNeedWhatModel | null> = ref(null);

    let setData = async (list: Ref, row: Ref) => {
      console.log("--->view FormNeedWhatModel", dataList, dataRow);
      dataList.value = list.value;
      // dataRow.value = row.value;
      //重新获取最新详细数据
      const ret = await apiFormNeedWhat.get(row.value?.id || 0);
      dataRow.value = ret.object;
    };

    let statusFormat = (status: number) => {
      return status == 0 ? "待处理" : "已处理";
    };

    return {
      drawerSize: "800px",
      setData,
      statusFormat,
      formModel: dataRow
    };
  }
});
