import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import { basemodel } from "./basemodel";
import { PageParams } from "./PageParams";

export class AdminLogModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("AdminLogModel");
    }
	/**
	 * id
	 */
	id?: number
	/**
	 * 用户id
	 */
	adminId?: number
    //@=>userId
    username?: string
    //@=>userId
    realname?: string
	/**
	 * 操作
	 */
	option?: string
	/**
	 * 日志消息
	 */
	message?: string
	/**
	 * 消息级别：
	 */
	level?: string
	/**
	 * 时间：默认为当前时间
	 */
	createTime?: Date
}
export interface AdminLogFilter {
	/**
	 * 用户id
	 */
	adminId?: number | null
	/**
	 * 操作
	 */
	option?: string | null
	/**
	 * 日志消息
	 */
	message?: string | null
	/**
	 * 消息级别：
	 */
	level?: string | null
}
// API URL： /api/v1/management/admin-log
// 管理端-管理员日志相关 | 管理端-管理员日志日志接口
export interface IAdminLog {
	// 获取 管理员日志列表
	// GET("/list")
	getList(pageParams: PageParams, filter: AdminLogFilter): Promise<RespBodyPager<AdminLogModel>>
	// 修改 管理员日志
	// PUT
	set(model: AdminLogModel): Promise<RespBody<boolean>>
	// 删除 管理员日志, 逗号分割id数组
	// DELETE("/{ids}")
	remove(ids: Array<number>): Promise<RespBody<number>>
}
