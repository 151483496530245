
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  onUpdated,
  reactive,
  ref
} from "vue";

export default defineComponent({
  name: "TablePageFrame",
  props: {
    heightOptions: {
      type: Number,
      default: 120
    },
    heightPagination: {
      type: Number,
      default: 40
    },
    tableOptionsSpace: {
      type: Boolean,
      default: () => true
    },
    pageParamsPageSize: {
      type: Number,
      default: () => 50
    },
    pageParamsPageCurrent: {
      type: Number,
      default: () => -1
    },
    pageParamsTotal: {
      type: Number,
      default: () => 0
    }
  },
  emits: [
    "on-body-size-change",
    "update:page-params-page-current",
    "update:page-params-page-size"
  ],
  setup(props, { emit }) {
    const handleCurrentChange = (current: number) => {
      emit("update:page-params-page-current", current);
    };
    const handleSizeChange = (size: number) => {
      emit("update:page-params-page-size", size);
    };

    let aaIndex = ref<number>(props.pageParamsPageCurrent);
    let aaSize = ref<number>(props.pageParamsPageSize);
    let total = ref<number>(props.pageParamsTotal);

    onUpdated(() => {
      aaIndex.value = props.pageParamsPageCurrent;
      aaSize.value = props.pageParamsPageSize;
      total.value = props.pageParamsTotal;
    });

    const cardbox = ref(null);

    const getsize = function () {
      if (cardbox.value) {
        let h = (cardbox.value as any).$el.clientHeight;
        emit("on-body-size-change", h);
      }
    };

    onMounted(() => {
      window.addEventListener("resize", getsize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getsize);
    });

    nextTick(() => {
      getsize();
    });

    let heightData = reactive({
      heightOptions: "0",
      heightBody: "100%",
      heightPagination: "0"
    });

    heightData.heightOptions = `${props.heightOptions ?? 0}px`;
    heightData.heightPagination = `${props.heightPagination ?? 0}px`;
    heightData.heightBody = `calc(100% - ${props.heightOptions ?? 0}px - ${
      props.heightPagination ?? 0
    }px)`;

    return {
      cardbox,
      aaIndex,
      aaSize,
      total,
      heightData,
      handleCurrentChange,
      handleSizeChange
    };
  }
});
