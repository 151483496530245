
import { defineComponent, ref, Ref } from "vue";
import { ServicePageModel } from "@/apis/interface/IServicePage";

export default defineComponent({
  setup() {
    let dataList: Ref<ServicePageModel[] | null> = ref([]);
    let dataRow: Ref<ServicePageModel | null> = ref(null);
    let companyId = ref(null);

    let setData = async (list: Ref, row: Ref) => {
      console.log("--->view ServicePageRole", dataList, dataRow);
      dataList.value = list.value;
      dataRow.value = row.value;
      // //重新获取最新详细数据
      // const ret = (await apiServicePage.get(
      //   companyId.value || 0,
      //   dataRow.value?.id || 0
      // )) as RespBody<ServicePageModel>;
      // dataRow.value = ret.object;
    };
    return {
      companyId,
      drawerSize: "800px",
      setData,
      formModel: dataRow
    };
  }
});
