import { ISysRole, SysRoleModel, SysRoleResourceModel, SysRoleResourceFiler } from "./interface/ISysRole";
import { RespBody, RespBodyList } from "@/apis/interface/Resp";
import request from "@/utils/request";
// API URL： /api/v1/management/Sys
// 管理端-用户相关 | 管理端-用户接口
export class ApiSysRole implements ISysRole {

    private path = "/api/v1/management/sys-role";

    // 根据roleId获取绑定绑定的资源列表
    // GET("/resource-bind-list")
    public async getResourceBind(filter: SysRoleResourceFiler): Promise<RespBodyList<SysRoleResourceModel>> {
        const url = `${this.path}/resource-bind-list`;
        const result = await request.get(url, { params: { ...filter } });
        console.table(["API@GET@根据roleId获取绑定绑定的资源列表", this.path, url, result]);
        const ret = result.data as RespBodyList<SysRoleResourceModel>;
        ret.object = ret.object.map((item: any) => new SysRoleResourceModel(item));
        return ret;
    }
    // 更新根据roleId绑定的资源列表
    // POST("/resource-bind-list")
    public async updateResourceBind(terminal: string, roleId: number, resourceIdList: Array<number>): Promise<RespBody<boolean>> {
        const url = `${this.path}/resource-bind-list`;
        const result = await request.post(url, resourceIdList, { params: { terminal, roleId } });
        console.table(["API@GET@更新根据roleId绑定的资源列表", this.path, url, result]);
        return result.data as RespBody<boolean>;
    }
    // 获取角色列表
    // GET("/list")
    public async getList(terminal: string): Promise<RespBodyList<SysRoleModel>> {
        const url = `${this.path}/list`;
        const result = await request.get(url, { params: { terminal } });
        console.table(["API@GET@获取角色列表", this.path, url, result]);
        const ret = result.data as RespBodyList<SysRoleModel>;
        ret.object = ret.object.map((item: any) => new SysRoleModel(item));
        return ret;
    }
    // 添加 权限
    // POST
    public async add(model: SysRoleModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.post(url, { ...model });
        console.table(["API@POST@添加 权限", this.path, url, result]);
        return result.data;
    }
    // 删除 权限
    // DELETE("/{ids}")
    public async remove(ids: Array<number>): Promise<RespBody<number>> {
        const url = `${this.path}/${ids.join(",")}`;
        const result = await request.delete(url);
        console.table(["API@DELETE@删除 权限", this.path, url, result]);
        return result.data;
    }

}