import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createBlock(_component_el_tabs, {
    class: "tab-bar",
    "model-value": _ctx.editableTabsValue,
    type: "card",
    editable: "",
    onInput: _ctx.changeValue,
    onTabRemove: _ctx.tabRemove
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editableTabs, (item) => {
        return (_openBlock(), _createBlock(_component_el_tab_pane, {
          key: item.name,
          label: item.title,
          name: item.name,
          closable: !!item.closable
        }, null, 8, ["label", "name", "closable"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["model-value", "onInput", "onTabRemove"]))
}