import { IUserLog, UserLogModel, UserLogFilter } from "./interface/IUserLog";
import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./interface/PageParams";
import request from "@/utils/request";
// API URL： /api/v1/management/user-log
// 管理端-用户相关 | 管理端-用户日志接口
export class ApiUserLog implements IUserLog {

    private path = "/api/v1/management/user-log";

    // 获取 用户日志列表
    // GET("/list")
    public async getList(pageParams: PageParams, filter: UserLogFilter): Promise<RespBodyPager<UserLogModel>> {
        const url = `${this.path}/list`;
        const result = await request.get(url, { params: { ...pageParams, ...filter } });
        console.table(["API@GET@获取 用户日志列表", this.path, url, pageParams, filter, result]);
        const ret = result.data as RespBodyPager<UserLogModel>;
        ret.object.listData = ret.object.listData?.map((item: any) => new UserLogModel(item));
        return ret;
    }
    // 修改 用户日志
    // PUT
    public async set(model: UserLogModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.put(url,  { ...model } );
        console.table(["API@SET@修改 用户日志", this.path, url, model, result]);
        return result.data;
    }
    // 删除 用户日志, 逗号分割id数组
    // DELETE("/{ids}")
    public async remove(ids: Array<number>): Promise<RespBody<number>> {
        const url = `${this.path}/${ids.join(",")}`;
        const result = await request.delete(url);
        console.table(["API@DELETE@删除 用户日志, 逗号分割id数组", this.path, url, ids, result]);
        return result.data;
    }
}