import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    size: "mini",
    modelValue: _ctx.currentUserId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentUserId) = $event)),
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    "remote-method": _ctx.remoteSearchMethod,
    loading: _ctx.loading,
    placeholder: "检索用户"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataList, (user) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: user.id,
          label: `${user.username} ${
        user.realname ? '(' + user.realname + ')' : ''
      }`,
          value: user.id
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "remote-method", "loading"]))
}