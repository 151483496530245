import { IServicePage, ServicePageModel, ServicePageFilter } from "./interface/IServicePage";
import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./interface/PageParams";
import request from "@/utils/request";
// API URL： /api/v1/management/service-page
// 管理端-服务相关 | 管理端-单页服务
export class ApiServicePage implements IServicePage {

    private path = "/api/v1/management/service-page";

    // 获取 单页服务列表
    // GET("/list")
    public async getList(companyId: number, pageParams: PageParams, filter: ServicePageFilter): Promise<RespBodyPager<ServicePageModel>> {
        const url = `${this.path}/list`;
        const result = await request.get(url, { params: { companyId, ...pageParams, ...filter } });
        console.table(["API@GET@获取 单页服务列表", this.path, url, pageParams, filter, result]);
        const ret = result.data as RespBodyPager<ServicePageModel>;
        ret.object.listData = ret.object.listData?.map((item: any) => new ServicePageModel(item));
        return ret;
    }
    // 获取 单页服务详情
    // GET("/{id}")
    public async get(companyId: number, id: number): Promise<RespBody<ServicePageModel>> {
        const url = `${this.path}/${id}`;
        const result = await request.get(url, { params: { companyId } });
        console.table(["API@GET@获取 单页服务详情", this.path, url, companyId, id, result]);
        const ret = result.data as RespBody<ServicePageModel>;
        ret.object = new ServicePageModel(ret.object);
        return result.data;
    }
    // 添加 单页服务
    // POST
    public async add(model: ServicePageModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.post(url,  { ...model } );
        console.table(["API@POST@添加 单页服务", this.path, url, model, result]);
        return result.data;
    }
    // 修改 单页服务
    // PUT
    public async set(model: ServicePageModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.put(url,  { ...model } );
        console.table(["API@GET@修改 单页服务", this.path, url, model, result]);
        return result.data;
    }
    // 删除 单页服务, 逗号分割id数组
    // DELETE("/{ids}")
    public async remove(ids: Array<number>): Promise<RespBody<number>> {
        const url = `${this.path}/${ids.join(",")}`;
        const result = await request.delete(url);
        console.table(["API@DELETE@删除 单页服务, 逗号分割id数组", this.path, url, ids, result]);
        return result.data;
    }
}