// declare let window: any;
import { LocalStorage } from "storage-manager-js";
declare let window: any;

function getSchemas() {
    if (!window.schemas)
        window.schemas = LocalStorage.get("schemas") as any;
    return window.schemas;
}

export class basemodel {

    private defaultFormModelName: string | null = null;

    public setDefaultFormModelName(modelName: string): void {
        this.defaultFormModelName = modelName;
    }

    constructor(data: any | null, modelName?: string | undefined) {
        if (modelName)
            this.defaultFormModelName = modelName;

        const that = this as any;

        if (data) {
            for (const name in data) {
                that[name] = data[name];
            }
        }
    }

    public getFormModel(modelName?: string): basemodel {

        if (!modelName && this.defaultFormModelName) {//设置默认值
            modelName = this.defaultFormModelName;
        }

        const model: { properties: any, type: string, title: string } = modelName ? (getSchemas())[modelName] : { id: {} };
        const properties = model.properties;

        console.log("表单:", modelName);
        console.table(properties);

        const m = {} as any;

        for (const name in this) {
            const val = this[name];
            if (typeof properties[name] !== "undefined") {
                // console.log("复制", name, val);
                m[name] = val;
            } else {
                // console.log("忽略", name, val);
            }
        }
        return m;
    }
}
