import { Resp } from "@/apis/interface/Resp";
import { ServiceBeanModel } from "@/apis/interface/IServiceBean";
import { basemodel } from "./basemodel";

export class ServiceGroupModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("ServiceGroupModel");
    }
    id?: number
    /**
     * 分组名称
     */
    groupName?: string
    /**
     * 组icon
     */
    groupIcon?: string
    /**
     * 排序
     */
    sort?: number
    /**
     * 禁用：0=否(默认)，1=是
     */
    isDisable?: boolean
    /**
     * 状态：0=正常(默认)
     */
    status?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
    /**
     * 创建时间：默认为当前时间（自动更新）
     */
    updateTime?: Date
}

//分组菜单
export class ServiceGroupMenuModel extends ServiceGroupModel {
    constructor(data: any | null) {
        super(data);
    }
    services: Array<ServiceBeanModel> = []
}

// API URL： /api/v1/management/service-group
// 管理端-服务相关 | 管理端-服务分组
export interface IServiceGroup {
    // 获取 服务分组列表
    // GET("/all")
    getAll(): Promise<Resp>
    // 添加 服务分组
    // POST
    add(model: ServiceGroupModel): Promise<Resp>
    // 修改 服务分组
    // PUT
    set(model: ServiceGroupModel): Promise<Resp>
}