
import { defineComponent, ref, Ref, inject } from "vue";
import { UserModel, IUser } from "@/apis/interface/IUser";
import { RespBody } from "@/apis/interface/Resp";

export default defineComponent({
  setup() {
    const apiUser = inject("apiUser") as IUser;

    let dataList: Ref<UserModel[] | null> = ref([]);
    let dataRow: Ref<UserModel | null> = ref(null);

    let setData = async (list: Ref, row: Ref) => {
      console.log("--->view UserModel", dataList, dataRow);
      dataList.value = list.value;
      dataRow.value = row.value;
      //重新获取最新详细数据
      const ret = (await apiUser.get(
        dataRow.value?.id || 0
      )) as RespBody<UserModel>;
      dataRow.value = ret.object;
    };
    let statusFormat = (status: number) => {
      // console.log(status);
      return "正常";
    };
    return {
      drawerSize: "800px",
      setData,
      statusFormat,
      formModel: dataRow
    };
  }
});
