import { Resp } from "@/apis/interface/Resp";
import { basemodel } from "./basemodel";

export class SysRoleResourceModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName(this.constructor.name);
    }
    id?: number
    /**
     * 终端
     */
    terminal?: string
    /**
     * 角色
     */
    roleId?: number
    /**
     * 资源
     */
    resourceId?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
}
export interface SysRoleResourceFiler {

    terminal?: string | null

    roleId?: number | null
}
export class SysRoleModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("SysRoleModel");
    }
    /**
     * id
     */
    id?: number | null
    /**
     * 终端
     */
    terminal?: string | null
    /**
     * 角色名称
     */
    roleName?: string | null
    /**
     * 描述
     */
    desc?: string | null
    /**
     * 创建时间
     */
    createTime?: Date | null
    /**
     * 修改时间
     */
    updateTime?: Date | null
}
// API URL： /api/v1/management/sys-role
// 管理端-权限管理
export interface ISysRole {
    // 根据roleId获取绑定绑定的资源列表
    // GET("/resource-bind")
    getResourceBind(filter: SysRoleResourceFiler): Promise<Resp>
    // 更新根据roleId绑定的资源列表
    // POST("/resource-bind")
    updateResourceBind(terminal: string, roleId: number, resourceIdList: Array<number>): Promise<Resp>
    // 获取角色列表
    // GET("/list")
    getList(terminal: string): Promise<Resp>
    // 添加 权限
    // POST
    add(model: SysRoleModel): Promise<Resp>
    // 删除 权限
    // DELETE("/{ids}")
    remove(ids: Array<number>): Promise<Resp>
}