import { Resp } from "@/apis/interface/Resp";
import { PageParams } from "./PageParams";
import { basemodel } from "./basemodel";
export class FormServiceJobInviteModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("FormServiceJobInviteModel");
    }
    id?: number
    /**
     * 发送人
     */
    senderUserId?: number
    //@=>senderUserId
    senderUsername?: string
    //@=>senderUserId
    senderRealname?: string
    /**
     * 服务类型id
     */
    serviceBeanId?: number
    /**
     * 服务类型标题
     */
    serviceBeanTitle?: string
    /**
     * 服务类型
     */
    serviceKey?: string
    /**
     * 服务id
     */
    serviceId?: number
    //@=>serviceId
    serviceTitle?: string
    /**
     * 公司名称
     */
    companyName?: string
    /**
     * 手机号
     */
    phone?: string
    /**
     * 邮箱
     */
    email?: string
    /**
     * 岗位需求
     */
    needPosition?: string
    /**
     * 需求描述
     */
    requirement?: string
    /**
     * 附件
     */
    attachment?: string
    /**
     * 附加信息
     */
    extras?: string
    /**
     * 禁用：0=否(默认)，1=是
     */
    isDisable?: boolean
    /**
     * 状态：0=正常(默认)
     */
    status?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
    /**
     * 创建时间：默认为当前时间（自动更新）
     */
    updateTime?: Date
}
export interface FormServiceJobInviteFilter {
    /**
     * 发送人
     */
    senderUserId?: number | null
    /**
     * 服务类型id
     */
    serviceBeanId?: number | null
    /**
     * 服务类型
     */
    serviceKey?: string | null
    /**
     * 服务id
     */
    serviceId?: number | null
    /**
     * 公司名称
     */
    companyName?: string | null
    /**
     * 手机号
     */
    phone?: string | null
    /**
     * 邮箱
     */
    email?: string | null
    /**
     * 需求描述
     */
    requirement?: string | null
}
// API URL： /api/v1/management/form-service-job-invite
// 管理端-表单相关 | 管理端-我要招聘表单
export interface IFormServiceJobInvite {
    // 获取 我要招聘表单列表
    // GET("/list")
    getList(pageParams: PageParams, filter: FormServiceJobInviteFilter): Promise<Resp>
    // 获取 我要招聘表单详情
    // GET("/{id}")
    get(id: number): Promise<Resp>
    // 添加 我要招聘表单
    // POST
    add(model: FormServiceJobInviteModel): Promise<Resp>
    // 修改 我要招聘表单
    // PUT
    set(model: FormServiceJobInviteModel): Promise<Resp>
    // 删除 我要招聘表单, 逗号分割id数组
    // DELETE("/{ids}")
    remove(ids: Array<number>): Promise<Resp>
}