import { createStore, useStore as vuexStore, Store } from "vuex";
import { State as AuthDataState } from "./modules/IAuthData";

// useStore类型
export function useStore(): Store<MyStoreType> {
  return vuexStore();
}
interface MyModuleState {
  authData: AuthDataState;
}
// 根store中的state的类型
// interface IRootState {
// 
// }
// type IStoreType = IRootState | IModuleState
type MyStoreType = MyModuleState;

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles: __WebpackModuleApi.RequireContext = require.context(
  "./modules",
  true,
  /\.ts$/
);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const ms: string[] = modulesFiles.keys().filter(item => item.indexOf("/I") < 0);
const modules = ms.reduce((modules: any, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
// console.log("store======= ", modules);
export default createStore({
  // state: {},
  // mutations: {},
  // actions: {},
  modules
});
