import { Resp } from "@/apis/interface/Resp";
import { PageParams } from "./PageParams";
import { basemodel } from "./basemodel";
export class InformationModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("InformationModel");
    }
    id?: number
    /**
     * 图标
     */
    icon?: string
    /**
     * 新闻标题
     */
    title?: string
    /**
     * 内容
     */
    content?: string
    /**
     * 附件
     */
    attachment?: string
    /**
     * 附加信息
     */
    extras?: string
    /**
     * 查看次数
     */
    viewCount?: number
    /**
     * 创建的管理员id
     */
    creatorAdminId?: number
    //@=>creatorAdminId
    creatorUsername?: string
    //@=>creatorAdminId
    creatorRealname?: string
    /**
     * law, activity, news, report
     */
    sourceType?: string
    /**
     * 服务类型id
     */
    serviceBeanId?: number
    /**
     * 服务类型标题
     */
    serviceBeanTitle?: string
    /**
     * 服务KEY：(service://common/3),(service://activity/10),(service://page/15)，NULL
     */
    serviceKey?: string
    /**
     * 资源id
     */
    serviceId?: number
    /**
     * 资源标题
     */
    serviceTitle?: string
    /**
     * 禁用：0=否(默认)，1=是
     */
    isDisable?: boolean
    /**
     * 状态：0=正常(默认)
     */
    status?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
    /**
     * 创建时间：默认为当前时间（自动更新）
     */
    updateTime?: Date
}
export interface InformationFilter {
    /**
     * 法律标题
     */
    title?: string | null
    /**
     * 内容
     */
    content?: string | null
    /**
     * law, activity, news, report
     */
    sourceType?: string | null
    /**
     * 服务类型id
     */
    serviceBeanId?: number | null
    /**
     * 服务KEY：(service://common/3),(service://activity/10),(service://page/15)，NULL
     */
    serviceKey?: string | null
    /**
     * 资源id
     */
    serviceId?: number | null
}
// API URL： /api/v1/management/information
// 管理端-信息
export interface IInformation {
    // 获取 信息列表
    // GET("/list")
    getList(pageParams: PageParams, filter: InformationFilter): Promise<Resp>
    // 获取 信息详情
    // GET("/{id}")
    get(id: number): Promise<Resp>
    // 添加 信息
    // POST
    add(model: InformationModel): Promise<Resp>
    // 修改 信息
    // PUT
    set(model: InformationModel): Promise<Resp>
    // 删除 信息, 逗号分割id数组
    // DELETE("/{ids}")
    remove(ids: Array<number>): Promise<Resp>
}
