
import { defineComponent, ref, toRefs, reactive, watch } from "vue";
import {
  getProvList,
  getCityList,
  getCountyList,
  getTownList
} from "@/utils/area-res";
import {
  SysCityModel,
  SysCountyModel,
  SysProvModel,
  SysTownModel
} from "@/apis/interface/ISystem";

export default defineComponent({
  props: {
    provCode: {
      type: String
    },
    cityCode: {
      type: String
    },
    countyCode: {
      type: String
    },
    townCode: {
      type: String
    },
    areaType: {
      type: String,
      default: () => "all"
    }
  },
  emits: [
    "update:prov-code",
    "update:city-code",
    "update:county-code",
    "update:town-code"
  ],
  setup(props, { emit }) {
    const { provCode, cityCode, countyCode, townCode } = toRefs(props);

    let provListData = ref<Array<SysProvModel>>(getProvList());
    let cityListData = ref<Array<SysCityModel>>(getCityList(provCode.value));
    let countyListData = ref<Array<SysCountyModel>>(
      getCountyList(cityCode.value)
    );
    let townListData = ref<Array<SysTownModel>>(getTownList(countyCode.value));

    let current = reactive({
      currentProvCode: provCode.value,
      currentCityCode: cityCode.value,
      currentCountyCode: countyCode.value,
      currentTownCode: townCode.value
    });

    watch(
      () => current.currentProvCode,
      (n) => {
        emit("update:prov-code", n);
        current.currentCityCode = undefined;
        current.currentCountyCode = undefined;
        current.currentTownCode = undefined;
        cityListData.value = getCityList(n);
        countyListData.value = [];
        townListData.value = [];
      }
    );

    watch(
      () => current.currentCityCode,
      (n) => {
        emit("update:city-code", n);
        current.currentCountyCode = undefined;
        current.currentTownCode = undefined;
        countyListData.value = getCountyList(n);
        townListData.value = [];
      }
    );

    watch(
      () => current.currentCountyCode,
      (n) => {
        emit("update:county-code", n);
        current.currentTownCode = undefined;
        townListData.value = getTownList(n);
      }
    );

    watch(
      () => current.currentTownCode,
      (n) => {
        emit("update:town-code", n);
      }
    );

    return {
      provListData,
      cityListData,
      countyListData,
      townListData,
      current
    };
  }
});
