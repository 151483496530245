import { RespBodyList, RespBody } from "@/apis/interface/Resp";
import { basemodel } from "./basemodel";
export class AdminRoleModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("AdminRoleModel");
    }
    /**
     * id
     */
    id?: number
    /**
     * 管理员
     */
    adminId?: number
    /**
     * 资源
     */
    roleId?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
}
// API URL： /api/v1/management/admin-role
// 管理端-管理员角色
export interface IAdminRole {
    // 获取 角色列表
    // GET("/list")
    getList(adminId: number | undefined, roleId: number | undefined): Promise<RespBodyList<AdminRoleModel>>

    // 修改 角色绑定
    // GET("/update")
    update(adminId: number, roleId: number, bind: boolean): Promise<RespBody<boolean>>
}