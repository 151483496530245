import { Resp } from "@/apis/interface/Resp";
import { basemodel } from "./basemodel";
import { PageParams } from "./PageParams";
export class UserModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("UserModel");
    }
    id?: number
    /**
     * 所属企业
     */
    companyId?: number
    //@=>companyId
    companyName?: number
    /**
     * 区县编码
     */
    currentCountyCode?: string
    /**
     * 用户名
     */
    username?: string
    /**
     * 姓名
     */
    realname?: string
    /**
     * phone
     */
    phone?: string
    /**
     * 密码
     */
    password?: string
    /**
     * 头像
     */
    avatar?: string
    /**
     * 性别
     */
    gender?: boolean
    /**
     * 邮箱
     */
    email?: string
    /**
     * vip权限：逗号分割
     */
    vipPower?: string
    /**
     * 禁用：0=否(默认)，1=是
     */
    isDisable?: boolean
    /**
     * 状态：0=正常(默认)
     */
    status?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
    /**
     * 创建时间：默认为当前时间（自动更新）
     */
    updateTime?: Date
    /**
     * 最后登录时间
     */
    loginTime?: Date
    /**
     * 登录token
     */
    loginToken?: string
}
export interface UserFilter {
    id?: number | null
    /**
     * 所属企业
     */
    companyId?: number | null
    /**
     * 用户名
     */
    username?: string | null
    /**
     * 姓名
     */
    realname?: string | null
    /**
     * phone
     */
    phone?: string | null
    /**
     * 性别
     */
    gender?: boolean | null
}
export interface UserSearch {
    /**
     * id
     */
    id?: number;
    /**
     * 搜索数量
     */
     key?: string
    /**
     * 搜索数量
     */
    size?: number
}
// API URL： /api/v1/management/user
// 管理端-用户相关 | 管理端-用户接口
export interface IUser {
    // 获取 管理员搜索列表
    // GET("/key-search")
    getListSearch(search: UserSearch): Promise<Resp>
    // 获取 用户列表
    // GET("/list")
    getList(pageParams: PageParams, filter: UserFilter): Promise<Resp>
    // 获取 用户详情
    // GET("/{id}")
    get(id: number): Promise<Resp>
    // 添加 用户
    // POST
    add(model: UserModel): Promise<Resp>
    // 修改 用户
    // PUT
    set(model: UserModel): Promise<Resp>
    // 删除 用户, 逗号分割id数组
    // DELETE("/{ids}")
    remove(ids: Array<number>): Promise<Resp>
}