import { ICompany, CompanyModel, CompanySearch, CompanyFilter } from "./interface/ICompany";
import { RespBody, RespBodyList, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./interface/PageParams";
import request from "@/utils/request";
// API URL： /api/v1/management/company
// 管理端-企业
export class ApiCompany implements ICompany {

    private path = "/api/v1/management/company";

    // 获取 企业搜索列表
    // GET("/key-search")
    public async getListSearch(search: CompanySearch): Promise<RespBodyList<CompanyModel>> {
        const url = `${this.path}/key-search`;
        const result = await request.get(url, { params: { ...search } });
        console.table(["API@GET@获取 企业搜索列表", this.path, url, search, result]);
        const ret = result.data as RespBodyList<CompanyModel>;
        ret.object = ret.object.map((item: any) => new CompanyModel(item));
        return ret;
    }
    // 获取 企业列表
    // GET("/list")
    public async getList(pageParams: PageParams, filter: CompanyFilter): Promise<RespBodyPager<CompanyModel>> {
        const url = `${this.path}/list`;
        const result = await request.get(url, { params: { ...pageParams, ...filter } });
        console.table(["API@GET@获取 企业列表", this.path, url, pageParams, filter, result]);
        const ret = result.data as RespBodyPager<CompanyModel>;
        ret.object.listData = ret.object.listData?.map((item: any) => new CompanyModel(item));
        return ret;
    }
    // 获取 企业详情
    // GET("/{id}")
    public async get(id: number): Promise<RespBody<CompanyModel>> {
        const url = `${this.path}/${id}`;
        const result = await request.get(url);
        console.table(["API@GET@获取 企业详情", this.path, url, id, result]);
        const ret = result.data as RespBody<CompanyModel>;
        ret.object = new CompanyModel(ret.object);
        return result.data;
    }
    // 添加 企业
    // POST
    public async add(model: CompanyModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.post(url, { ...model });
        console.table(["API@POST@添加 企业", this.path, url, model, result]);
        return result.data;
    }
    // 修改 企业
    // PUT
    public async set(model: CompanyModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.put(url, { ...model });
        console.table(["API@PUT@修改 企业", this.path, url, model, result]);
        return result.data;
    }
    // 删除 企业, 逗号分割id数组
    // DELETE("/{ids}")
    public async remove(ids: Array<number>): Promise<RespBody<number>> {
        const url = `${this.path}/${ids.join(",")}`;
        const result = await request.delete(url);
        console.table(["API@DELETE@删除 企业, 逗号分割id数组", this.path, url, ids, result]);
        return result.data;
    }
}