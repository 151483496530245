import { Resp } from "@/apis/interface/Resp";
import { basemodel } from "./basemodel";
import { PageParams } from "./PageParams";
export class ServicePageModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("ServicePageModel");
    }
    id?: number
    /**
     * 服务类别
     */
    serviceBeanId?: number
    //@=>serviceBeanId
    serviceBeanTitle?: string
    /**
     * 图标
     */
    icon?: string
    /**
     * 标题
     */
    title?: string
    /**
     * 内容
     */
    content?: string
    /**
     * 附件
     */
    attachment?: string
    /**
     * 创建的管理员id
     */
    creatorAdminId?: number
    //@=>creatorAdminId
    creatorUsername?: string
    //@=>creatorAdminId
    creatorRealname?: string
    hasForm?: string
    isOpen?: boolean
    /**
     * 禁用：0=否(默认)，1=是
     */
    isDisable?: boolean
    /**
     * 状态：0=正常(默认)
     */
    status?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
    /**
     * 创建时间：默认为当前时间（自动更新）
     */
    updateTime?: Date
}
export interface ServicePageFilter {
    /**
     * 服务类别
     */
    serviceBeanId?: number | null
    /**
     * 标题
     */
    title?: string | null
    /**
     * 内容
     */
    content?: string | null
}
// API URL： /api/v1/management/service-page
// 管理端-服务相关 | 管理端-单页服务
export interface IServicePage {
    // 获取 单页服务列表
    // GET("/list")
    getList(companyId: number, pageParams: PageParams, filter: ServicePageFilter): Promise<Resp>
    // 获取 单页服务详情
    // GET("/{id}")
    get(companyId: number, id: number): Promise<Resp>
    // 添加 单页服务
    // POST
    add(model: ServicePageModel): Promise<Resp>
    // 修改 单页服务
    // PUT
    set(model: ServicePageModel): Promise<Resp>
    // 删除 单页服务, 逗号分割id数组
    // DELETE("/{ids}")
    remove(ids: Array<number>): Promise<Resp>
}
