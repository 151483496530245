
import { defineComponent, computed, inject, ref, Ref } from "vue";
import { RespBody } from "@/apis/interface/Resp";
import {
  IServiceGroup,
  ServiceGroupModel
} from "@/apis/interface/IServiceGroup";

import type { ElForm } from "element-plus";
type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  props: {},
  setup() {
    const apiServiceGroup = inject("apiServiceGroup") as IServiceGroup;
    const formRef = ref<FormInstance>();

    let dataList: Ref<ServiceGroupModel[] | null> = ref([]);
    let dataRow: Ref<ServiceGroupModel | null> = ref(null);

    let setData = (list: Ref, row: Ref) => {
      dataList.value = list.value;
      dataRow.value = row.value;
      console.log("--->edit ServiceGroupModel", dataList, dataRow);
    };

    let isEdit = computed(() => {
      return Boolean(dataRow.value?.id);
    });

    let submit = async () => {
      let form = formRef.value;
      if (form) {
        form.validate(async (valid) => {
          if (!valid) {
            return false;
          }
          if (dataRow.value) {
            let model: ServiceGroupModel = dataRow.value.getFormModel();
            switch (isEdit.value) {
              case true: {
                let result = (await apiServiceGroup.set(
                  model
                )) as RespBody<boolean>;
                return result.object;
              }
              case false: {
                let result = (await apiServiceGroup.add(
                  model
                )) as RespBody<boolean>;
                return result.object;
              }
            }
          }
        });
      }
    };

    return {
      dialogWidth: "800px",
      isEdit,
      setData,
      submit,
      formModel: dataRow,
      formRef
    };
  }
});
