import { Resp, RespBody } from "@/apis/interface/Resp";
import { basemodel } from "./basemodel";

export class UserRoleModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("UserRoleModel");
    }
    /**
     * id
     */
    id?: number
    /**
     * 用户
     */
    userId?: number
    /**
     * 资源
     */
    roleId?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
}
// API URL： /api/v1/management/user-role
// 管理端-用户角色
export interface IUserRole {
    // 获取 角色列表
    // GET("/list")
    getList(userId: number | undefined, roleId: number | undefined): Promise<Resp>

    // 修改 角色绑定
    // GET("/update")
    update(userId: number, roleId: number, bind: boolean): Promise<RespBody<boolean>>
}