
import { defineComponent, computed, inject, ref, Ref } from "vue";
import { RespBody } from "@/apis/interface/Resp";
import {
  IFormServiceJobInvite,
  FormServiceJobInviteModel
} from "@/apis/interface/IFormServiceJobInvite";

import type { ElForm } from "element-plus";
type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  props: {},
  setup() {
    const apiFormServiceJobInvite = inject(
      "apiFormServiceJobInvite"
    ) as IFormServiceJobInvite;
    const formRef = ref<FormInstance>();
    const attachmentBoxRef = ref();

    let dataList: Ref<FormServiceJobInviteModel[] | null> = ref([]);
    let dataRow: Ref<FormServiceJobInviteModel | null> = ref(null);
    let activeTab = ref("first");

    let setData = (list: Ref, row: Ref) => {
      dataList.value = list.value;
      dataRow.value = row.value;
      console.log("--->edit FormServiceJobInviteModel", dataList, dataRow);
    };

    let isEdit = computed(() => {
      return Boolean(dataRow.value?.id);
    });

    let checkForm = async () => {
      let form = formRef.value;
      if (form) {
        try {
          // let attachmentValide = attachmentBoxRef.value.isValide();
          // if (!attachmentValide) {
          //   activeTab.value = "second";
          //   return false;
          // }
          if (await form.validate()) {
            return true;
          }
        } catch (error) {
          console.log(error);
        }
      }
      return false;
    };

    let submit = async () => {
      if ((await checkForm()) && dataRow.value) {
        let model: FormServiceJobInviteModel = dataRow.value.getFormModel();
        switch (isEdit.value) {
          case true: {
            let result = (await apiFormServiceJobInvite.set(
              model
            )) as RespBody<boolean>;
            return result.object;
          }
          case false: {
            let result = (await apiFormServiceJobInvite.add(
              model
            )) as RespBody<boolean>;
            return result.object;
          }
        }
      }
    };

    return {
      dialogWidth: "800px",
      isEdit,
      activeTab,
      setData,
      submit,
      formModel: dataRow,
      formRef
    };
  }
});
