
import { defineComponent, computed, inject, ref, Ref } from "vue";
import { RespBody } from "@/apis/interface/Resp";
import {
  IFormServiceGeneral,
  FormServiceGeneralModel
} from "@/apis/interface/IFormServiceGeneral";

export default defineComponent({
  props: {},
  setup() {
    const apiFormServiceGeneral = inject(
      "apiFormServiceGeneral"
    ) as IFormServiceGeneral;

    let dataList: Ref<FormServiceGeneralModel[] | null> = ref([]);
    let dataRow: Ref<FormServiceGeneralModel | null> = ref(null);
    let activeTab = ref("first");

    let setData = (list: Ref, row: Ref) => {
      dataList.value = list.value;
      dataRow.value = row.value;
      console.log("--->edit FormServiceGeneralModel", dataList, dataRow);
    };

    let isEdit = computed(() => {
      return Boolean(dataRow.value?.id);
    });

    let submit = async () => {
      if (dataRow.value) {
        let model: FormServiceGeneralModel = dataRow.value.getFormModel();
        switch (isEdit.value) {
          case true: {
            let result = (await apiFormServiceGeneral.set(
              model
            )) as RespBody<boolean>;
            return result.object;
          }
          case false: {
            let result = (await apiFormServiceGeneral.add(
              model
            )) as RespBody<boolean>;
            return result.object;
          }
        }
      }
    };

    return {
      dialogWidth: "800px",
      isEdit,
      activeTab,
      setData,
      submit,
      formModel: dataRow
    };
  }
});
