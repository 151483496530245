import TabBar from "./TabBar.vue";
import Imager from "./Imager.vue";
import TableOptions from "./TableOptions.vue";
import TablePageFrame from "./TablePageFrame.vue";
import AdminSelector from "./AdminSelector.vue";
import AreaSelector from "./AreaSelector.vue";
import AreaCodeSelector from "./AreaCodeSelector.vue";
import AttachmentBox from "./AttachmentBox.vue";
import CompanySelector from "./CompanySelector.vue";
import ImageUploader from "./ImageUploader.vue";
import FileUploader from "./FileUploader.vue";
import GeneralPageFrame from "./GeneralPageFrame.vue";
import IconSelector from "./IconSelector.vue";
import ServiceBeanSelector from "./ServiceBeanSelector.vue";
import ServiceGroupSelector from "./ServiceGroupSelector.vue";
import UserSelector from "./UserSelector.vue";
import FormtypeSelector from "./FormtypeSelector.vue";
import Editor from "./Editor.vue";
import Preview from "./Preview.vue";

class RegisterComponent {
  static install: (e: import("vue").App<any>) => void;
}
RegisterComponent.install = function (app) {
  app.component("tab-bar", TabBar);
  app.component("table-options", TableOptions);
  app.component("table-page-frame", TablePageFrame);
  app.component("imager", Imager);
  app.component("admin-selector", AdminSelector);
  app.component("area-selector", AreaSelector);
  app.component("attachment-box", AttachmentBox);
  app.component("area-code-selector", AreaCodeSelector);
  app.component("company-selector", CompanySelector);
  app.component("image-uploader", ImageUploader);
  app.component("file-uploader", FileUploader);
  app.component("general-page-frame", GeneralPageFrame);
  app.component("icon-selector", IconSelector);
  app.component("service-group-selector", ServiceGroupSelector);
  app.component("service-bean-selector", ServiceBeanSelector);
  app.component("user-selector", UserSelector);
  app.component("formtype-selector", FormtypeSelector);
  app.component("editor", Editor);
  app.component("preview", Preview);
};
export default RegisterComponent;
