import { IAdminLog, AdminLogModel, AdminLogFilter } from "./interface/IAdminLog";
import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./interface/PageParams";
import request from "@/utils/request";

// API URL： /api/v1/management/admin-log
// 管理端-管理员日志相关 | 管理端-管理员日志日志接口
export class ApiAdminLog implements IAdminLog {

	private path = "/api/v1/management/admin-log";

	//  获取 管理员日志列表")
	// GET("/list")
	public async getList(pageParams: PageParams, filter: AdminLogFilter): Promise<RespBodyPager<AdminLogModel>> {
		const url = `${this.path}/list`;
		const result = await request.get(url, { params: { ...pageParams, ...filter } });
		console.table(["API@GET@获取 管理员日志列表", this.path, url, pageParams, filter, result]);
        const ret = result.data as RespBodyPager<AdminLogModel>;
        ret.object.listData = ret.object.listData?.map((item: any) => new AdminLogModel(item));
        return ret;
	}
	//  修改 管理员日志")
	// PUT
	public async set(model: AdminLogModel): Promise<RespBody<boolean>> {
        console.table(model);
		const url = `${this.path}`;
		const result = await request.put(url,  { ...model } );
		console.table(["API@PUT@修改 管理员日志", this.path, url, model, result]);
		return result.data;
	}
	//  删除 管理员日志, 逗号分割id数组
	// DELETE("/{ids}")
	public async remove(ids: Array<number>): Promise<RespBody<number>> {
		const url = `${this.path}/${ids.join(",")}`;
		const result = await request.delete(url);
		console.table(["API@DELETE@删除 管理员日志, 逗号分割id数组", this.path, url, ids, result]);
		return result.data;
	}
}