import {
  createRouter,
  createWebHashHistory,
  RouteLocationNormalized,
  RouteRecordNormalized,
  NavigationGuardNext,
  RouteRecordRaw
} from "vue-router";
//进度条
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { MyRoute } from "./IRoute";
import store from "@/store";
import { LocalStorage } from "storage-manager-js";

const routes: Array<MyRoute> = [
  {
    path: "/login",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/views/Auth/Login.vue"),
    name: "Login"
  }
  // {
  //   path: "/",
  //   component: () => import("../layout/Layout.vue")
  // }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes as Array<RouteRecordRaw>
});

// 前置守卫
router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    NProgress.start();
    if (!LocalStorage.has("9028472") && to.path != "/login") {
      router.push("/login");
    } else {
      next();
    }
  }
);
// 后置守卫
router.afterEach((to: RouteLocationNormalized) => {
  NProgress.done();
  try {
    //设置标题
    if (to.meta.name) {
      document.title = to.meta.name as string;
    }
  } catch (err) {
    err;
  }
  // 当前路由的所有嵌套路径片段的路由记录
  const crumbList: Array<RouteRecordNormalized> = to.matched;
  //顶部面包屑
  store.commit("authData/SET_CRUMB_LIST", crumbList);
  // aside选中的active
  store.commit("authData/SET_CURRENT_MENU", to.name);
});

export default router;
