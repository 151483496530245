import { IAdmin, AdminModel, AdminSearch, AdminFilter } from "./interface/IAdmin";
import { RespBody, RespBodyList, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./interface/PageParams";
import request from "@/utils/request";
import { AdminHome, IAdminHome } from "@/apis/interface/IAdminHome";
// API URL： /api/v1/management/admin-home
// 管理端-首页相关 | 管理端-首页接口
export class ApiAdminHome implements IAdminHome {

    private path = "/api/v1/management/admin-home";

    // 获取 首页数量
    // GET("/count")
    public async getCount(): Promise<RespBody<AdminHome>> {
        const url = `${this.path}/count`;
        const result = await request.get(url);
        console.table(["API@GET@获取 首页数量", this.path, url, result]);
        // return result.data;
        const ret = result.data as RespBody<AdminHome>;
        return ret;
    }
}
