import { IFormServiceJobInvite, FormServiceJobInviteModel, FormServiceJobInviteFilter } from "./interface/IFormServiceJobInvite";
import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./interface/PageParams";
import request from "@/utils/request";
// API URL： /api/v1/management/form-service-job-invite
// 管理端-表单相关 | 管理端-我要招聘表单
export class ApiFormServiceJobInvite implements IFormServiceJobInvite {

    private path = "/api/v1/management/form-service-job-invite";

    // 获取 我要招聘表单列表
    // GET("/list")
    public async getList(pageParams: PageParams, filter: FormServiceJobInviteFilter): Promise<RespBodyPager<FormServiceJobInviteModel>> {
        const url = `${this.path}/list`;
        const result = await request.get(url, { params: { ...pageParams, ...filter } });
        console.table(["API@GET@获取 我要招聘表单列表", this.path, url, pageParams, filter, result]);
        const ret = result.data as RespBodyPager<FormServiceJobInviteModel>;
        ret.object.listData = ret.object.listData?.map((item: any) => new FormServiceJobInviteModel(item));
        return ret;
    }
    // 获取 我要招聘表单详情
    // GET("/{id}")
    public async get(id: number): Promise<RespBody<FormServiceJobInviteModel>> {
        const url = `${this.path}/${id}`;
        const result = await request.get(url);
        console.table(["API@GET@获取 我要招聘表单详情", this.path, url, id, result]);
        const ret = result.data as RespBody<FormServiceJobInviteModel>;
        ret.object = new FormServiceJobInviteModel(ret.object);
        return result.data;
    }
    // 添加 我要招聘表单
    // POST
    public async add(model: FormServiceJobInviteModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.post(url,  { ...model } );
        console.table(["API@POST@添加 我要招聘表单", this.path, url, model, result]);
        return result.data;
    }
    // 修改 我要招聘表单
    // PUT
    public async set(model: FormServiceJobInviteModel): Promise<RespBody<boolean>> {
        console.table(model);
        const url = `${this.path}`;
        const result = await request.put(url,  { ...model } );
        console.table(["API@PUT@修改 我要招聘表单", this.path, url, model, result]);
        return result.data;
    }
    // 删除 我要招聘表单, 逗号分割id数组
    // DELETE("/{ids}")
    public async remove(ids: Array<number>): Promise<RespBody<number>> {
        const url = `${this.path}/${ids.join(",")}`;
        const result = await request.delete(url);
        console.table(["API@DELETE@删除 我要招聘表单, 逗号分割id数组", this.path, url, ids, result]);
        return result.data;
    }
}