import "@/styles/element-variables.scss"; // 自定义element样式
import ElementPlus from "element-plus";
import * as icons from "@element-plus/icons";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import { QuillEditor } from "@vueup/vue-quill";

class UseElement {
  static install: (e: import("vue").App<any>) => void;
}

UseElement.install = function (app) {
  const iconNames: Array<string> = Object.keys(icons);
  const list: any = icons;

  for (const item of iconNames) {
    app.component(`Icon${item}`, list[item]); // 文件
  }

  app.use(ElementPlus, {
    locale: zhCn,
    size: "small"
  }); // 注册element-plus

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],        // toggled buttons
    ["blockquote", "code-block"],

    // [{ "header": 1 }, { "header": 2 }],               // custom button values
    [{ "list": "ordered" }, { "list": "bullet" }],
    // [{ "script": "sub" }, { "script": "super" }],      // superscript/subscript
    [{ "indent": "-1" }, { "indent": "+1" }],          // outdent/indent
    // [{ "direction": "rtl" }],                         // text direction

    // [{ "size": ["small", false, "large", "huge"] }],  // custom dropdown
    [{ "header": [1, 2, 3, 4, 5, 6, false] }],

    [{ "color": [] }, { "background": [] }],          // dropdown with defaults from theme
    // [{ "font": [] }],
    [{ "align": [] }],
    ["link", "image"],

    ["clean"]                                         // remove formatting button
  ];

  // define your options
  const globalOptions = {
    // readOnly: true,
    // debug: "info",
    theme: "snow",
    modules: {
      toolbar: toolbarOptions
    },
    placeholder: "请编辑内容"
  };
  // set default globalOptions prop
  QuillEditor.props.globalOptions.default = () => globalOptions;
  // register QuillEditor component
  app.component("quill-editor", QuillEditor);

};
export default UseElement;
