import { RespBody, RespBodyList, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./PageParams";
import { basemodel } from "./basemodel";
import { number } from "echarts/core";
export class CompanyModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("CompanyModel");
    }
    id?: number
    /**
     * 组织机构代码
     */
    uid?: string
    /**
     * 公司名称
     */
    companyName?: string
    /**
     * 曾用名
     */
    companyNameFormer?: string
    /**
     * 省代码
     */
    provCode?: string
    /**
     * 城市代码
     */
    cityCode?: string
    /**
     * 区县代码
     */
    countyCode?: string
    /**
     * 乡镇代码
     */
    townCode?: string
    /**
     * logo
     */
    logo?: string
    /**
     * 爬虫数据源
     */
    detailData?: MoreCompnayInfoModel
    /**
     * 介绍
     */
    introduction?: string
    /**
     * 详细地址
     */
    address?: string
    /**
     * 网站
     */
    webSite?: string
    /**
     * 邮箱
     */
    email?: string
    /**
     * 法人
     */
    legalPerson?: string
    /**
     * 禁用：0=否(默认)，1=是
     */
    isDisable?: boolean
    /**
     * 状态：0=正常(默认)
     */
    status?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
    /**
     * 创建时间：默认为当前时间（自动更新）
     */
    updateTime?: Date
}
export class MoreCompnayInfoModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("MoreCompnayInfoModel");
    }
    id?: number

    /**
     * 税收
     */
    tax?: number

    /**
     * 企业类型
     */
    enType?: string

    /**
     * 行业
     */
    industry?: string

    /**
     * 国家
     */
    stateCode?: string

    /**
     * 销售收入
     */
    saleIncome?: number

    /**
     * 营业状况
     */
    compaStatus?: string

    /**
     * 企业电话
     */
    phoneNumber?: string

    /**
     * 参保人数
     */
    insuredCount?: number

    /**
     * 营业范围
     */
    businessScope?: string

    /**
     * 成立时间
     */
    establishDate?: string

    /**
     * 人员规模
     */
    employeeAmount?: number

    /**
     * 注册资本
     */
    registerCapital?: number

    /**
     * 运营年数
     */
    orgOperationTime?: number

    /**
     * 实缴资本
     */
    contributedCapital?: number

    /**
     * 知识产权数
     */
    intellectualAmount?: number

    /**
     * 网站备案数
     */
    icpCopyrightAmount?: number

    /**
     * 登记机关
     */
    registrationAuthority?: string

    /**
     * 软件著作数
     */
    softwareCopyrightAmount?: number

    /**
     * 注册商标数
     */
    registeredTrademarkAmount?: number

    /**
     * 工商注册号
     */
    businessRegistrationNumber?: string

    /**
     * 组织机构代码
     */
    organizingInstitutionBarCode?: string

    /**
     * 专利数
     */
    inventionPatentLicenseAuthorizationAmount?: number
}
export interface CompanySearch {
    /**
     * id
     */
    id?: number;
    /**
     * 搜索数量
     */
     key?: string
    /**
     * 搜索数量
     */
    size?: number
}
export interface CompanyFilter {
    id?: number | null
    /**
     * 组织机构代码
     */
    uid?: string | null
    /**
     * 公司名称
     */
    companyName?: string | null
    /**
     * 曾用名
     */
    companyNameFormer?: string | null
    /**
     * 省代码
     */
    provCode?: string | null
    /**
     * 城市代码
     */
    cityCode?: string | null
    /**
     * 区县代码
     */
    countyCode?: string | null
    /**
     * 乡镇代码
     */
    townCode?: string | null
    /**
     * 法人
     */
    legalPerson?: string | null
}
// API URL： /api/v1/management/company
// 管理端-企业
export interface ICompany {
    // 获取 企业搜索列表
    // GET("/key-search")
    getListSearch(search: CompanySearch): Promise<RespBodyList<any>>
    // 获取 企业列表
    // GET("/list")
    getList(pageParams: PageParams, filter: CompanyFilter): Promise<RespBodyPager<CompanyModel>>
    // 获取 企业详情
    // GET("/{id}")
    get(id: number): Promise<RespBody<CompanyModel>>
    // 添加 企业
    // POST
    add(model: CompanyModel): Promise<RespBody<boolean>>
    // 修改 企业
    // PUT
    set(model: CompanyModel): Promise<RespBody<boolean>>
    // 删除 企业, 逗号分割id数组
    // DELETE("/{ids}")
    remove(ids: Array<number>): Promise<RespBody<number>>
}