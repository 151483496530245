
import { defineComponent, inject, reactive, ref, Ref } from "vue";
import { RespBody } from "@/apis/interface/Resp";
import { IAdminLog, AdminLogModel } from "@/apis/interface/IAdminLog";

export default defineComponent({
  props: {},
  setup() {
    const apiAdminLog = inject("apiAdminLog") as IAdminLog;

    let dataList: Ref<AdminLogModel[] | null> = ref([]);
    let dataRow: Ref<AdminLogModel | null> = ref(null);

    let setData = (list: Ref, row: Ref) => {
      dataList.value = list.value;
      dataRow.value = row.value;
      console.log("--->edit AdminLogModel", dataList, dataRow);
    };

    let submit = async () => {
      if (dataRow.value) {
        let model: AdminLogModel = dataRow.value.getFormModel();
        let result = (await apiAdminLog.set(model)) as RespBody<boolean>;
        return result.object;
      }
    };

    let rules = reactive({});

    return {
      dialogWidth: "1000px",
      setData,
      submit,
      formModel: dataRow,
      rules
    };
  }
});
