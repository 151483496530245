import { RespBody } from "@/apis/interface/Resp";
import request from "@/utils/request";

import { IOther } from "./interface/IOther";
// API URL： /api/v1/management/other
// 管理端-其他
export class ApiOther implements IOther {

    private path = "/api/v1/management/other";

    // 表格数据排序
    // GET("/sort/{table}/{ids}")
    public async sortIndex(table: string, ids: Array<number>): Promise<RespBody<number>> {
        const url = `${this.path}/sort/${table}/${ids.join(",")}`;
        const result = await request.get(url);
        console.table(["API@GET@表格数据排序", this.path, url, result]);
        const ret = result.data as RespBody<number>;
        return ret;
    }
}