import { Resp } from "@/apis/interface/Resp";
import { PageParams } from "./PageParams";
import { basemodel } from "./basemodel";
export class ServiceGeneralModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("ServiceGeneralModel");
    }
    id?: number
    /**
     * 服务类别
     */
    serviceBeanId?: number
    //@=>serviceBeanId
    serviceBeanTitle?: string
    /**
     * 父节点必须为文件夹 dir
     */
    parentId?: number
    //@=>parentId
    parentNodeTitle?: string
    /**
     * 节点类型：dir/file
     */
    nodeType?: string
    /**
     * 节点图标
     */
    nodeIcon?: string
    /**
     * 节点标题
     */
    nodeTitle?: string
    /**
     * 服务介绍
     */
    nodeContent?: string
    /**
     * 附件
     */
    attachment?: string
    /**
     * 附件json
     */
    attachmentJson?: Array<any> | null;
    /**
     * 拥有的tab列表
     */
    // tabList?: string
    tabListJson?: Array<any>
    /**
     * 所在父节点的tab
     */
    // inTabs?: string
    inTabsJson?: Array<any>
    /**
     * 表单类型
     */
    hasForm?: string
    /**
     * 开放
     */
    isOpen?: boolean
    /**
     * 加锁
     */
    isLocked?: boolean
    /**
     * 禁用：0=否(默认)，1=是
     */
    isDisable?: boolean
    /**
     * 顺序
     */
    indexSort?: number
    /**
     * 状态：0=正常(默认)
     */
    status?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
    /**
     * 创建时间：默认为当前时间（自动更新）
     */
    updateTime?: Date
}

//树形节点
export class ServiceGeneralNodeModel extends ServiceGeneralModel {
    //父节点
    pid?: number | null | undefined
    //节点名称
    name?: string | null | undefined
    //子节点
    children?: Array<ServiceGeneralModel> | null | undefined
}

export interface ServiceGeneralFilter {
    /**
     * 服务类别
     */
    serviceBeanId: number
    /**
     * 父节点必须为文件夹 dir
     */
    parentId?: number | null
    /**
     * 节点类型：dir/file
     */
    nodeType?: string | null
    /**
     * 节点标题
     */
    nodeTitle?: string | null
    /**
     * 服务介绍
     */
    nodeContent?: string | null
}
// API URL： /api/v1/management/service-general
// 管理端-服务相关 | 管理端-通用树服务
export interface IServiceGeneral {
    // 获取公共服务树-目录
    // GET("/tree")
    serviceTree(companyId: number, filter: ServiceGeneralFilter): Promise<Resp>
    // 2 获取公共服务树-列表-根据父id
    // GET("/parent-{parentId}/list")
    serviceSimpleListByParent(companyId: number, parentId: number): Promise<Resp>
    // 获取 通用树服务列表
    // GET("/list")
    getList(companyId: number, pageParams: PageParams, filter: ServiceGeneralFilter): Promise<Resp>
    // 获取 通用树服务详情
    // GET("/{id}")
    get(companyId: number, id: number): Promise<Resp>
    // 添加 通用树服务
    // POST
    add(model: ServiceGeneralModel): Promise<Resp>
    // 修改 通用树服务
    // PUT
    set(model: ServiceGeneralModel): Promise<Resp>
    // 删除 通用树服务, 逗号分割id数组
    // DELETE("/{ids}")
    remove(ids: Array<number>): Promise<Resp>
}
