import { Resp } from "@/apis/interface/Resp";
import { basemodel } from "./basemodel";
export class ServiceBeanModel extends basemodel {
    constructor(data: any | null) {
        super(data);
        super.setDefaultFormModelName("ServiceBeanModel");
    }
    id?: number
    /**
     * 服务key：用于判断页面跳转逻辑
     */
    serviceKey?: string
    /**
     * 服务名称
     */
    serviceTitle?: string
    /**
     * 服务icon
     */
    serviceIcon?: string
    /**
     * 服务介绍
     */
    serviceIntro?: string
    /**
     * 分组
     */
    groupId?: number
    /**
     * 文件夹的风格样式：枚举
     */
    dirStyle?: number
    /**
     * 需要的vip权限
     */
    needVipPower?: string
    /**
     * 排序
     */
     indexSort?: number
    /**
     * 禁用：0=否(默认)，1=是
     */
    isDisable?: boolean
    /**
     * 状态：0=正常(默认)
     */
    status?: number
    /**
     * 修改时间：默认为当前时间
     */
    createTime?: Date
    /**
     * 创建时间：默认为当前时间（自动更新）
     */
    updateTime?: Date
}
export interface ServiceBeanFilter {
    /**
     * 服务名称
     */
    serviceTitle?: string | null
    groupIds: Array<number>
}
// API URL： /api/v1/management/service-bean
// 管理端-服务相关 | 管理端-服务导航
export interface IServiceBean {
    // 获取公共服务 组-菜单
    // GET("/menu")
    menus(): Promise<Resp>
    // 获取 组列表")
    // GET("/all")
    getAll(): Promise<Resp>
    // 添加 组")
    // POST
    add(model: ServiceBeanModel): Promise<Resp>
    // 修改 组")
    // PUT
    set(model: ServiceBeanModel): Promise<Resp>
    // 删除 模塊, 逗号分割id数组
    // DELETE("/{ids}")
    remove(ids: Array<number>): Promise<Resp>
}
